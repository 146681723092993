/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback, useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useAccessControlContext } from 'security/context/AccessControl/AccessControlContext';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import BaseModel from 'models/BaseModel';
import BaseModelQueries from 'queries/BaseModelQueries';
import { getErrorMessage } from 'queries/ErrorResponse';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ResponsiveButton from 'components/ResponsiveButton';
import SoftBox from 'softUI/components/SoftBox';

export type InputFieldsSectionProps<TModel extends BaseModel> = {
  tabIndexForInputs: number;
  editedData: TModel;
  availability?: any;
  labels?: any;
  updateInputField: (
    fieldName: keyof TModel,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
  updateField: (fieldName: keyof TModel) => (value: any) => void;
  validationErrors: ValidationErrors<TModel>;
  readOnly: boolean;
};

type InputFieldsRendererFunction<TModel extends BaseModel> = {
  (props: InputFieldsSectionProps<TModel>): React.ReactNode;
};

type EditorProps<TModel extends BaseModel> = {
  data: TModel;
  queriesImpl: BaseModelQueries<TModel>;
  saveMode: 'create' | 'update';
  validationHelper?: ValidationHelper<TModel>;
  showRemoveButton?: boolean;
  showSaveAndCloseButton?: boolean;
  showSaveButton?: boolean;
  showResetButton?: boolean;
  onCloseHandler: () => void;
  onAfterCreateHandler?: (id: number) => void;
  renderInputFieldsSection: InputFieldsRendererFunction<TModel>;
  confirmRemovalDialogText?: string;
  confirmSaveDialogText?: string;
  confirmCloseDialogText?: string;
  confirmResetDialogText?: string;
  getAvailability?: (data: TModel) => any;
  getLabels?: (data: TModel) => any;
  allowEmptySave?: boolean;
};

const Editor = <TModel extends BaseModel>({
  showRemoveButton = true,
  showSaveAndCloseButton = true,
  showSaveButton = true,
  showResetButton = true,
  ...props
}: EditorProps<TModel>) => {
  const createEntityQuery = props.queriesImpl.create();
  const updateEntityQuery = props.queriesImpl.update();
  const deleteEntityQuery = props.queriesImpl.delete();

  const [initialData, setInitialData] = useState(props.data);
  const [editedData, setEditedData] = useState(props.data);
  const [confirmResetDialogIsOpen, setConfirmResetDialogIsOpen] =
    useState(false);
  const [confirmCloseDialogIsOpen, setConfirmCloseDialogIsOpen] =
    useState(false);
  const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
    useState(false);
  const [confirmSaveDialogState, setConfirmSaveDialogState] = useState({
    isOpen: false,
    closeAfterSave: false,
  });
  const [progressBackdropIsOpen, setProgressBackdropIsOpen] = useState(false);
  const [notificationErrorMessage, setNotificationErrorMessage] = useState('');
  const [lastEditedFieldName, setLastEditedFieldName] =
    useState<keyof TModel>();
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors<TModel>
  >({});
  // if parent component updated input data then state should be updated
  useEffect(() => {
    setInitialData(props.data);
    setEditedData(props.data);
  }, [props.data]);

  const validateInputs = (
    editedData: TModel,
    fieldName: keyof TModel | undefined,
  ) => {
    if (fieldName && props.validationHelper) {
      props.validationHelper
        .validate(editedData, fieldName)
        .then((err) =>
          props.validationHelper!.updateValidationErrors(
            err,
            setValidationErrors,
          ),
        );
    }
  };

  // validateInputs will be called no more than once in 500 ms
  const debouncedValidate = useCallback(
    debounce(validateInputs, 500, { maxWait: 500 }),
    [],
  );

  // performing validation when editedData is changed
  useEffect(() => {
    debouncedValidate(editedData, lastEditedFieldName);
  }, [editedData, lastEditedFieldName]);

  const editedDataIsInDirtyState = isEditedDataInDirtyState();
  useEffect(() => {
    function beforeunloadListener(e: any) {
      if (editedDataIsInDirtyState) {
        e.preventDefault();
        e.returnValue = 'onbeforeunload';
        return '';
      }
    }
    window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, [editedDataIsInDirtyState]);
  const navigationBlocker = useBlocker(editedDataIsInDirtyState);

  function isEditedDataInDirtyState() {
    const initialDataAsRequest = props.queriesImpl.toRequest(initialData);
    const editedDataAsRequest = props.queriesImpl.toRequest(editedData);
    return !isEqual(initialDataAsRequest, editedDataAsRequest);
  }

  function resetDirtyState() {
    setInitialData(editedData);
  }

  function updateEditedDataField(fieldName: keyof TModel, value: any) {
    setEditedData((prev) => {
      const updated = { ...prev } as any;
      if (value !== undefined) {
        updated[fieldName] = value;
      } else {
        delete updated[fieldName];
      }
      return updated;
    });
    setLastEditedFieldName(fieldName);
  }

  const updateInputField = (fieldName: keyof TModel) =>
    useCallback(
      (_: any, value: any) => updateEditedDataField(fieldName, value),
      [],
    );

  const updateField = (fieldName: keyof TModel) =>
    useCallback((value: any) => updateEditedDataField(fieldName, value), []);

  async function handleSaveBtnClick(closeAfterSave: boolean) {
    if (props.validationHelper) {
      const allFieldsErrors = await props.validationHelper.validate(editedData);
      if (Object.keys(allFieldsErrors).length > 0) {
        setLastEditedFieldName(undefined);
        props.validationHelper.updateValidationErrors(
          allFieldsErrors,
          setValidationErrors,
        );
        return;
      }
    }
    setConfirmSaveDialogState({
      isOpen: true,
      closeAfterSave: closeAfterSave,
    });
  }

  // page close - navigation to other page
  // must perform navigation only after component is rerendered,
  // so that Blocker state is reset with refreshed dirty state flag
  function performClosePageEffect() {
    setTimeout(() => {
      props.onCloseHandler();
    });
  }
  function performAfterCreateEffect(id: number) {
    setTimeout(() => {
      props.onAfterCreateHandler?.(id);
    });
  }

  const createMode = props.saveMode == 'create';

  function handleConfirmSaveDialogResult(isConfirmed: boolean) {
    const closeAfterSave = confirmSaveDialogState.closeAfterSave;
    setConfirmSaveDialogState({ isOpen: false, closeAfterSave: false });
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          const mutationQuery = createMode
            ? createEntityQuery
            : updateEntityQuery;
          const response = await mutationQuery.mutateAsync(
            props.queriesImpl.toRequest(editedData),
          );
          const responseData = response.data;
          if (responseData?.success && responseData?.data) {
            setInitialData(responseData.data);
            setEditedData(responseData.data);
          } else {
            resetDirtyState();
          }
          if (closeAfterSave) {
            performClosePageEffect();
          } else if (createMode) {
            performAfterCreateEffect(responseData.data.id!);
          }
        } catch (error: any) {
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Saving failed'),
          );
        } finally {
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }

  function handleResetBtnClick() {
    setConfirmResetDialogIsOpen(true);
  }

  function handleConfirmResetDialogResult(isConfirmed: boolean) {
    setConfirmResetDialogIsOpen(false);
    if (isConfirmed) {
      setEditedData(initialData);
    }
  }

  function handleCloseBtnClick() {
    if (editedDataIsInDirtyState) {
      setConfirmCloseDialogIsOpen(true);
    } else {
      performClosePageEffect();
    }
  }

  function handleConfirmCloseDialogResult(isConfirmed: boolean) {
    setConfirmCloseDialogIsOpen(false);
    if (isConfirmed) {
      resetDirtyState();
      performClosePageEffect();
    }
  }

  function handleDeleteBtnClick() {
    setConfirmDeleteDialogIsOpen(true);
  }

  function handleConfirmDeleteDialogResult(isConfirmed: boolean) {
    setConfirmDeleteDialogIsOpen(false);
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          await deleteEntityQuery.mutateAsync(editedData.id!);
          resetDirtyState();
          performClosePageEffect();
        } catch (error: any) {
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Removal failed'),
          );
        } finally {
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }

  const accessControlContext = useAccessControlContext();
  const canEdit = createMode
    ? accessControlContext.canCreate()
    : accessControlContext.canUpdate(initialData);
  const canSave = createMode
    ? accessControlContext.canCreate(editedData)
    : accessControlContext.canUpdate(editedData);
  return (
    <>
      <SoftBox pb={1.5} display='flex' justifyContent='end'>
        {canEdit && !!showSaveAndCloseButton && (
          <ResponsiveButton
            label='Save &amp; Close'
            variant='contained'
            icon='done'
            color='info'
            onClick={() => handleSaveBtnClick(true)}
            tabIndex={2}
            disabled={
              (!editedDataIsInDirtyState && !props.allowEmptySave) || !canSave
            }
          />
        )}
        {canEdit && !!showSaveButton && (
          <ResponsiveButton
            label='Save'
            variant='outlined'
            icon='save'
            iconBaseClass='material-icons-outlined'
            color='info'
            onClick={() => handleSaveBtnClick(false)}
            tabIndex={2}
            disabled={
              (!editedDataIsInDirtyState && !props.allowEmptySave) || !canSave
            }
          />
        )}
        {canEdit && !!showResetButton && (
          <ResponsiveButton
            label='Reset'
            variant='outlined'
            icon='restart_alt'
            color='info'
            onClick={handleResetBtnClick}
            tabIndex={2}
            disabled={!editedDataIsInDirtyState}
          />
        )}
        <ResponsiveButton
          label='Close'
          variant='outlined'
          icon='close'
          color='info'
          onClick={handleCloseBtnClick}
          tabIndex={2}
        />
        {accessControlContext.canDelete(initialData) && !!showRemoveButton && (
          <ResponsiveButton
            label='Remove'
            variant='outlined'
            icon='delete'
            color='primary'
            onClick={handleDeleteBtnClick}
            tabIndex={2}
          />
        )}
      </SoftBox>
      {props.renderInputFieldsSection({
        tabIndexForInputs: 1,
        availability: props.getAvailability?.(editedData),
        labels: props.getLabels?.(editedData),
        editedData,
        updateInputField,
        updateField,
        validationErrors,
        readOnly: !canEdit,
      })}
      <ConfirmationDialog
        open={confirmResetDialogIsOpen}
        title='Reset'
        description={props.confirmResetDialogText || 'Confirm reset changes?'}
        resultCallback={handleConfirmResetDialogResult}
      />
      <ConfirmationDialog
        open={confirmCloseDialogIsOpen}
        title='Close'
        description={
          props.confirmCloseDialogText || 'Confirm reject and close?'
        }
        resultCallback={handleConfirmCloseDialogResult}
      />
      <ConfirmationDialog
        open={confirmDeleteDialogIsOpen}
        title='Delete'
        description={props.confirmRemovalDialogText || 'Confirm removal?'}
        resultCallback={handleConfirmDeleteDialogResult}
      />
      <ConfirmationDialog
        open={confirmSaveDialogState.isOpen}
        title='Save'
        description={props.confirmSaveDialogText || 'Confirm save?'}
        resultCallback={handleConfirmSaveDialogResult}
      />
      <ConfirmationDialog
        open={navigationBlocker.state === 'blocked'}
        title='Unsaved changes'
        description='You have changes to current entity. Do you want to discard changes and leave the page?'
        resultCallback={(isConfirmed) => {
          setTimeout(
            () =>
              isConfirmed
                ? navigationBlocker.proceed!()
                : navigationBlocker.reset!(),
            0,
          );
        }}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progressBackdropIsOpen}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar
        open={!!notificationErrorMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationErrorMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          <pre style={{ fontFamily: 'inherit' }}>
            {notificationErrorMessage}
          </pre>
        </Alert>
      </Snackbar>
    </>
  );
};

export default Editor;
