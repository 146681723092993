/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Grid from '@mui/material/Grid';
import { getClientNameWithUid } from 'models/Client';
import { getConsultantFullNameWithPayroll } from 'models/Consultant';
import { getEmployeeFullName } from 'models/Employee';
import BaseReportWithTimesheetsFilter from 'models/reports/BaseReportWithTimesheetsFilter';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import clientQueries from 'queries/ClientQueries';
import consultantQueries from 'queries/ConsultantQueries';
import employeeQueries from 'queries/EmployeeQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import locationQueries from 'queries/LocationQueries';
import statusQueries from 'queries/StatusQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import { ReportParametersProps } from './DatesForReportParameters';

const TimesheetsFilterForReportParameters = <
  T extends BaseReportWithTimesheetsFilter,
>(
  props: ReportParametersProps<T> & {
    tabIndexForInputs?: number;
  },
) => {
  const updateFilter = (
    field: keyof BaseReportWithTimesheetsFilter,
    value: any,
  ) => {
    props.onUpdateParameters((prevState) => {
      const updatedFilter = {
        ...prevState,
      };
      updatedFilter[field] = value;
      return updatedFilter;
    });
  };

  const parametersList = [
    {
      label: 'Consultants',
      modelQueries: consultantQueries,
      optionLabelGetter: getConsultantFullNameWithPayroll,
      setIDs: (ids: number[]) => updateFilter('consultantIds', ids),
      ids: () => props.parameters.consultantIds,
    },
    {
      label: 'Employment Types',
      modelQueries: employmentTypeQueries,
      optionFilter: (t) => t.isActive,
      setIDs: (ids: number[]) => updateFilter('employmentTypeIds', ids),
      ids: () => props.parameters.employmentTypeIds,
    },
    {
      label: 'Clients',
      modelQueries: clientQueries,
      optionLabelGetter: getClientNameWithUid,
      setIDs: (ids: number[]) => updateFilter('clientIds', ids),
      ids: () => props.parameters.clientIds,
    },
    {
      label: 'Account Executives',
      modelQueries: employeeQueries,
      optionFilter: (e) => e.isActive,
      optionLabelGetter: getEmployeeFullName,
      setIDs: (ids: number[]) => updateFilter('accountExecutiveIds', ids),
      ids: () => props.parameters.accountExecutiveIds,
    },
    {
      label: 'Recruiters',
      modelQueries: employeeQueries,
      optionFilter: (e) => e.isActive,
      optionLabelGetter: getEmployeeFullName,
      setIDs: (ids: number[]) => updateFilter('recruiterIds', ids),
      ids: () => props.parameters.recruiterIds,
    },
    {
      label: 'Locations',
      modelQueries: locationQueries,
      optionFilter: (l) => l.isActive,
      setIDs: (ids: number[]) => updateFilter('locationIds', ids),
      ids: () => props.parameters.locationIds,
    },
    {
      label: 'Statuses',
      modelQueries: statusQueries,
      setIDs: (ids: number[]) => updateFilter('statusIds', ids),
      ids: () => props.parameters.statusIds,
    },
  ] as {
    label: string;
    modelQueries: BaseFetchingQueries<any>;
    optionFilter?: (o: any) => boolean;
    optionLabelGetter?: (o: any) => string;
    setIDs: (ids: number[]) => void;
    ids: () => number[] | undefined;
  }[];

  return (
    <Grid container item spacing={2} xs={12}>
      {parametersList.map((parameter, index) => (
        <Grid item xs={6} md={4} xl={3} key={index}>
          <AutocompleteWithLabel
            key={index}
            label={parameter.label}
            tabIndex={props.tabIndexForInputs}
            optionsFetcher={parameter.modelQueries}
            optionFilter={parameter.optionFilter}
            optionLabelGetter={parameter.optionLabelGetter}
            selectedValues={parameter.ids()?.map((id) => ({ id: id })) || []}
            onChange={(_, ids) => {
              parameter.setIDs(ids || []);
            }}
            disabled={props.disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TimesheetsFilterForReportParameters;
