/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';
import IFilterContext from './IFilterContext';

export type ITimesheetFilterContext = IFilterContext;

const TimesheetFilterContext = createContext<ITimesheetFilterContext>(
  {} as ITimesheetFilterContext,
);
export default TimesheetFilterContext;

export function useTimesheetFilterContext() {
  return useContext(TimesheetFilterContext);
}
