/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { Link } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import theme from 'softUI/assets/theme';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';

type ResponsiveButtonProps = {
  label: string;
  variant?: 'text' | 'contained' | 'outlined' | 'gradient';
  color:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  icon: string;
  iconBaseClass?: string;
  disabled?: boolean;
  tabIndex?: number;
  linkTo?: string;
  linkState?: any;
  onClick?: () => void;
};

const ResponsiveButton = (props: ResponsiveButtonProps) => {
  // @ts-ignore
  const isLargeScreen = theme.breakpoints.up('xxl');
  // @ts-ignore
  const isSmallScreen = theme.breakpoints.down('xxl');

  return (
    <SoftBox ml={1}>
      <SoftButton
        size='small'
        variant={props.variant || 'outlined'}
        component={props.linkTo ? Link : 'label'}
        to={props.linkTo}
        state={props.linkState}
        color={props.color}
        sx={{
          [isSmallScreen]: {
            display: 'none',
          },
        }}
        disabled={props.disabled}
        onClick={props.onClick}
        tabIndex={props.tabIndex}
      >
        {props.label}
      </SoftButton>
      <IconButton
        size='medium'
        component={props.linkTo ? Link : 'label'}
        to={props.linkTo}
        state={props.linkState}
        color={props.color}
        sx={{
          [isLargeScreen]: {
            display: 'none',
          },
          mt: -0.5,
        }}
        disabled={props.disabled}
        onClick={props.onClick}
        tabIndex={props.tabIndex}
      >
        <Icon title={props.label} baseClassName={props.iconBaseClass}>
          {props.icon}
        </Icon>
      </IconButton>
    </SoftBox>
  );
};

export default ResponsiveButton;
