/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import UserRole from 'models/UserRole';
import BaseModelQueries from './BaseModelQueries';

class UserRoleQueries extends BaseModelQueries<UserRole> {
  constructor(url: string) {
    super(url);
  }
}

export default new UserRoleQueries('/userroles');
