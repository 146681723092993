/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import isEqual from 'lodash/isEqual';
import Period from 'models/Period/Period';
import InputWithLabel from 'components/input/InputWithLabel';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';

type TimesheetRatesCardProps = {
  period: Period | undefined;
  labels: any;
};

const TimesheetRatesCard = ({ period, labels }: TimesheetRatesCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftBox
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <SoftTypography variant='h6' sx={{ pl: 1 }}>
          Period
        </SoftTypography>
        <SoftTypography fontWeight='medium' variant='caption' noWrap>
          <Link to={'/periods/edit/' + period?.id}>#{period?.id}</Link>
        </SoftTypography>
      </SoftBox>
      <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label={labels.billRatePermFee}
            type='number'
            readOnly
            tabIndex={-1}
            value={period?.billRatePermFee}
          />
        </Grid2>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label={labels.payRateBaseSalary}
            type='number'
            readOnly
            tabIndex={-1}
            value={period?.payRateBaseSalary}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  TimesheetRatesCard,
  (before, after) =>
    before.period?.billRatePermFee === after.period?.billRatePermFee &&
    before.period?.payRateBaseSalary === after.period?.payRateBaseSalary &&
    isEqual(before.labels, after.labels),
) as typeof TimesheetRatesCard;
