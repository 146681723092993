/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import RolePermission, { PermissionResource } from 'models/RolePermission';

type GroupedPermissions = Map<PermissionResource, RolePermission[]>;
export default GroupedPermissions;

export function getGroupedPermissions(
  permissions: RolePermission[],
): GroupedPermissions {
  const result = new Map<PermissionResource, RolePermission[]>();
  permissions.forEach((permission) => {
    if (!result.has(permission.resource)) {
      result.set(permission.resource, [permission]);
    } else {
      result.get(permission.resource)!.push(permission);
    }
  });
  return result;
}
