/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import GroupedPermissions from 'security/GroupedPermissions';
import UserRole from 'models/UserRole';
import { RouteAcl } from './RouteType';

export default function matchAcl(
  userRole: UserRole,
  permissions: GroupedPermissions | null,
  acl: RouteAcl,
) {
  if (acl.administrator) return userRole.isAdministrator;
  if (userRole.isAdministrator || !acl.resource || !acl.action) return true;

  const resourcePermissions = permissions?.get(acl.resource);
  if (!resourcePermissions?.length) {
    return false;
  }
  return !!resourcePermissions.find((perm) =>
    perm.actions.includes(acl.action!),
  );
}
