/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import moment from 'moment';
import Period from 'models/Period/Period';
import RolePermission, { PermissionAction } from 'models/RolePermission';
import Timesheet from 'models/Timesheet/Timesheet';

export function matchActionAndConditions(
  permissions: RolePermission[],
  action: PermissionAction,
  entity: any,
) {
  for (const permission of permissions) {
    if (!permission.actions.includes(action)) {
      continue;
    }
    if (!hasConditions(permission) || !entity) {
      return true;
    }
    if (permission.resource === 'PERIODS') {
      if (matchConditionsForPeriod(permission, entity as Period)) return true;
    } else if (permission.resource === 'TIMESHEETS') {
      if (matchConditionsForTimesheet(permission, entity as Timesheet))
        return true;
    } else {
      // Other resources don't support conditions.
      return true;
    }
  }
  return false;
}

function hasConditions(permission: RolePermission): boolean {
  return (
    (permission.startDate ?? null) !== null ||
    (permission.endDate ?? null) !== null ||
    (permission.maxAgeDays ?? null) !== null
  );
}

function matchConditionsForPeriod(
  permission: RolePermission,
  period: Period,
): boolean {
  if (
    !!permission.startDate &&
    (!period.endDate ||
      moment(permission.startDate).isAfter(moment(period.endDate)))
  ) {
    return false;
  }

  if (
    !!permission.endDate &&
    (!period.startDate ||
      moment(permission.endDate).isBefore(moment(period.startDate)))
  ) {
    return false;
  }

  return (
    (permission.maxAgeDays ?? null) === null ||
    (!!period.endDate &&
      !moment(period.endDate)
        .add(permission.maxAgeDays, 'days')
        .isBefore(moment()))
  );
}

function matchConditionsForTimesheet(
  permission: RolePermission,
  timesheet: Timesheet,
): boolean {
  if (
    !!permission.startDate &&
    (!timesheet.weekEndingDate ||
      moment(permission.startDate).isAfter(moment(timesheet.weekEndingDate)))
  ) {
    return false;
  }
  if (
    !!permission.endDate &&
    (!timesheet.weekEndingDate ||
      moment(permission.endDate).isBefore(moment(timesheet.weekEndingDate)))
  ) {
    return false;
  }
  return (
    (permission.maxAgeDays ?? null) === null ||
    (!!timesheet.weekEndingDate &&
      !moment(timesheet.weekEndingDate)
        .add(permission.maxAgeDays, 'days')
        .isBefore(moment()))
  );
}
