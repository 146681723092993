/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { HTMLAttributes, memo, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import SoftBox from 'softUI/components/SoftBox';
import SoftInput from 'softUI/components/SoftInput';
import SoftTypography from 'softUI/components/SoftTypography';
import OptionsFetcher from './OptionsFetcher';

export type SelectAndPercentEntry<OptionValue> = {
  id?: number;
  selected?: OptionValue;
  percent?: number;
};

type SelectAndPercentProps<OptionValue, OptionKey> = {
  autoFocus?: boolean;
  disabled?: boolean;
  isOptionEqualTo?: (option1: OptionValue, option2: OptionValue) => boolean;
  onChange: (value: SelectAndPercentEntry<OptionValue>) => void;
  optionIdGetter?: (option: OptionValue) => OptionKey;
  optionLabelGetter?: (option: OptionValue) => string | undefined;
  optionsFetcher?:
    | OptionsFetcher<OptionValue>
    | BaseFetchingQueries<OptionValue>;
  readOnly?: boolean;
  tabIndex?: number;
  value?: SelectAndPercentEntry<OptionValue>;
};

const SelectAndPercent = <OptionValue, OptionKey = number | string>({
  // @ts-ignore
  isOptionEqualTo = (option1, option2) => option1?.id === option2?.id,
  // @ts-ignore
  optionIdGetter = (option) => option.id,
  // @ts-ignore
  optionLabelGetter = (option) => option.name,
  ...props
}: SelectAndPercentProps<OptionValue, OptionKey>) => {
  // Options - the source.
  const { isFetching, isSuccess, data, refetch } =
    (props.optionsFetcher &&
      ('getAll' in props.optionsFetcher
        ? props.optionsFetcher.getAll()
        : props.optionsFetcher())) ||
    {};

  // Options - filter & extract the labels.
  const [options, optionLabels] = useMemo(() => {
    if (isSuccess && data) {
      const labels = new Map<any, string>(
        data.map((option) => [
          optionIdGetter(option),
          optionLabelGetter(option) || '',
        ]),
      );
      return [data, labels];
    } else {
      return [[], new Map()];
    }
  }, [isSuccess, data, optionIdGetter, optionLabelGetter]);

  // Options - prefetch to be able to show the selected option labels.
  const requiresPrefetch = useMemo(
    () =>
      !optionLabels.size &&
      props.value?.selected &&
      optionLabelGetter(props.value.selected) === undefined,
    [optionLabels, props.value, optionLabelGetter],
  );
  const fetchOptions = () => !isSuccess && refetch?.();
  if (requiresPrefetch) fetchOptions();

  // Options - equal function for marking selected options.
  const isOptionEqualToValue = (option1: OptionValue, option2: OptionValue) => {
    return (
      option1 === option2 ||
      (typeof option1 === 'object' &&
        typeof option2 === 'object' &&
        isOptionEqualTo(option1, option2))
    );
  };

  // Selected - the intermediate state while the select box is open. Changes are to be propagated once the box is closed.
  const [selected, setSelected] = useState<OptionValue | null>(null);
  const [percent, setPercent] = useState<number>();

  // Selected - propagate changed selectedValues.
  useEffect(() => {
    setSelected(props.value?.selected || null);
    setPercent(props.value?.percent);
  }, [props.value]);

  // Selected - sieve non-existent options.
  useEffect(() => {
    if (selected && optionLabels.size) {
      if (!optionLabels.has(optionIdGetter(selected))) {
        setSelected(null);
        callOnChange(undefined, percent);
      }
    }
  }, [optionLabels]);

  // Selected - on Autocomplete change event handler.
  const onAutocompleteChangeHandler = (_: any, value: OptionValue | null) => {
    setSelected(value);
    callOnChange(value, percent);
  };

  // Selected - on Percent change event handler.
  const onPercentChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valueAsNumber: number | undefined = undefined;
    if (!isEmpty(e.target.value)) {
      valueAsNumber = +e.target.value;
      if (isNaN(valueAsNumber)) {
        valueAsNumber = undefined;
      }
    }
    setPercent(valueAsNumber);
    callOnChange(selected, valueAsNumber);
  };

  // Selected - on Percent change event handler.
  const onPercentBlurHandler = () => {
    if (
      percent !== undefined &&
      (isNaN(percent) || percent < 0 || percent > 100)
    ) {
      setPercent(undefined);
      callOnChange(selected, undefined);
    }
  };

  // Selected - propagate changes.
  const callOnChange = (
    newOption?: OptionValue | null,
    newPercent?: number,
  ) => {
    props.onChange({
      id: props.value?.id,
      selected: newOption ? newOption : undefined,
      percent: newPercent,
    });
  };

  // Option render - the label resolver.
  const getOptionLabel = (option: OptionValue): string => {
    if (optionLabels.size) {
      const result = optionLabels.get(optionIdGetter(option));
      if (result) {
        return result;
      }
    }
    return optionLabelGetter(option) ?? 'Loading...';
  };

  // Option render.
  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OptionValue,
  ) => {
    return (
      <li
        {...props}
        key={(optionIdGetter(option) as any) || (option as string)}
      >
        {getOptionLabel(option)}
      </li>
    );
  };

  // Input - the renderer.
  const renderInput = (params: any) => (
    <TextField
      {...params}
      placeholder={props.disabled || props.readOnly ? '' : 'Type to search'}
      autoFocus={props.autoFocus}
      inputProps={{
        ...params.inputProps,
        tabIndex: props.tabIndex,
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isFetching ? (
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ position: 'absolute', right: '12px' }}
              />
            ) : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      sx={{
        '.MuiInputBase-input': {
          width: '100% !important',
        },
        '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
          display: props.readOnly ? 'none' : '',
        },
      }}
    />
  );
  return (
    <SoftBox display='flex'>
      <Autocomplete
        disabled={props.disabled}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={isFetching}
        options={options}
        onOpen={fetchOptions}
        onChange={onAutocompleteChangeHandler}
        renderOption={renderOption}
        renderInput={renderInput}
        readOnly={props.readOnly}
        size='small'
        sx={{
          flex: '1 1 auto',
        }}
        value={selected}
      />
      <SoftInput
        size='small'
        readOnly={props.readOnly}
        disabled={props.disabled}
        variant='outlined'
        endAdornment={
          <SoftTypography
            variant='inherit'
            sx={{ position: 'absolute', right: '8px' }}
          >
            %
          </SoftTypography>
        }
        value={percent !== undefined ? percent : ''}
        onChange={onPercentChangeHandler}
        onBlur={onPercentBlurHandler}
        onKeyPress={(e: KeyboardEvent) => {
          if (!/[0-9]/.test(e.key)) {
            // otherwise Mozilla allows to enter any character when input is empty ignoring onChange event
            e.preventDefault();
          }
        }}
        slotProps={{
          input: {
            tabIndex: props.tabIndex,
          },
        }}
        sx={{
          flex: '0 0 60px',
          ml: 0.5,
        }}
      />
    </SoftBox>
  );
};

export default memo(
  SelectAndPercent,
  (before, after) =>
    before.autoFocus === after.autoFocus &&
    before.disabled === after.disabled &&
    before.isOptionEqualTo === after.isOptionEqualTo &&
    before.onChange === after.onChange &&
    before.optionIdGetter === after.optionIdGetter &&
    before.optionLabelGetter === after.optionLabelGetter &&
    before.optionsFetcher === after.optionsFetcher &&
    before.readOnly === after.readOnly &&
    before.tabIndex === after.tabIndex &&
    isEqual(before.value, after.value),
) as typeof SelectAndPercent;
