/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { Icon } from '@mui/material';
import UnrestrictedAccessContextProvider from 'security/context/AccessControl/UnrestrictedAccessContextProvider';
import UserRole, { isReporter } from 'models/UserRole';
import userRoleQueries from 'queries/UserRoleQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage';

const UserRolesPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'userRoleDataTableState',
    showAddButton: true,
    addNewEntityPageUrl: '/user-roles/new',
    showEditButton: true,
    formatEditEntityPageUrl(id) {
      return `/user-roles/edit/${id}`;
    },
    showRemoveButton: true,
    formatRemoveConfirmationDialogText(id) {
      return `Are you sure you want to remove selected User Role with ID ${id}?`;
    },
    showExportButton: false,
    gridBoxHeight: 'calc(100vh - 117px)',
    queriesImpl: userRoleQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      {
        field: 'isAdministrator',
        headerName: 'Administrator',
        width: 80,
        type: 'boolean',
        renderCell(params) {
          const role = params.row as UserRole;
          return role.isAdministrator ? (
            <Icon fontSize='medium' color='success'>
              check
            </Icon>
          ) : (
            <Icon fontSize='medium' color='error'>
              close
            </Icon>
          );
        },
      },
      {
        field: 'isReporter',
        headerName: 'Reporter',
        width: 80,
        type: 'boolean',
        renderCell(params) {
          const role = params.row as UserRole;
          return isReporter(role) ? (
            <Icon fontSize='medium' color='success'>
              check
            </Icon>
          ) : (
            <Icon fontSize='medium' color='error'>
              close
            </Icon>
          );
        },
      },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return (
    <UnrestrictedAccessContextProvider>
      <BaseListPage {...properties} />
    </UnrestrictedAccessContextProvider>
  );
};

export default UserRolesPage;
