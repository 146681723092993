/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import { getClientNameWithUid } from 'models/Client';
import InvoicingReport from 'models/reports/InvoicingReport';
import clientQueries from 'queries/ClientQueries';
import invoicingFrequencyQueries from 'queries/InvoicingFrequencyQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import DatesForReportParameters from './filters/DatesForReportParameters';

const validationHelper = new ValidationHelper<InvoicingReport>(
  Yup.object({
    clientIds: Yup.array().required('At least one Client must be present'),
    invoicingFrequencyIds: Yup.array().required(
      'At least one Invoicing Frequency must be present',
    ),
  }),
);

const InvoicingReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'Invoicing',
  } as InvoicingReport);
  const [validationErrors, setValidationErrors] = useState(
    {} as ValidationErrors<InvoicingReport>,
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Invoicing Report'
      reportType={'Invoicing'}
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      onLoadParameters={setParameters}
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pt={3}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Clients'
              tabIndex={1}
              optionsFetcher={clientQueries}
              optionLabelGetter={getClientNameWithUid}
              selectedValues={
                parameters.clientIds?.map((id) => ({ id: id }) as any) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  clientIds: ids || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.clientIds?.join(';')}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Invoicing frequencies'
              tabIndex={1}
              optionsFetcher={invoicingFrequencyQueries}
              selectedValues={
                parameters.invoicingFrequencyIds?.map(
                  (id) => ({ id: id }) as any,
                ) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  invoicingFrequencyIds: ids || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.invoicingFrequencyIds?.join(';')}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default InvoicingReportPage;
