/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import isEqual from 'lodash/isEqual';
import RolePermission, { PermissionAction } from 'models/RolePermission';
import PermissionConditions, {
  extractConditions,
} from './PermissionConditions';
import appPermissions, {
  PermissionActionDef,
  PermissionDef,
  appPermissionActions,
} from './appPermissions';

type PermissionTableRow = {
  rowKey: string;
  permissionDef: PermissionDef;
  conditions?: PermissionConditions;
  actions: Set<PermissionAction>;
};
type PermissionTableModel = {
  actions: PermissionActionDef[];
  permissions: PermissionTableRow[];
};
export default PermissionTableModel;

export function convertToModel(
  permissions: RolePermission[],
): PermissionTableModel {
  const result = [] as PermissionTableRow[];
  appPermissions.forEach((permissionDef) => {
    let actions = [] as PermissionAction[];
    const actionsByConditions = [] as {
      key: number;
      conditions: PermissionConditions;
      actions: PermissionAction[];
    }[];
    // Iterate over permissions and collect actions + per conditions.
    permissions.forEach((perm) => {
      if (permissionDef.resources.has(perm.resource)) {
        const conditions = extractConditions(perm);
        if (!conditions) {
          actions = perm.actions;
        } else {
          let subgroup = actionsByConditions.find((i) =>
            isEqual(conditions, i.conditions),
          );
          if (subgroup) {
            subgroup.actions = perm.actions;
          } else {
            subgroup = {
              key: 0,
              conditions,
              actions: perm.actions,
            };
            actionsByConditions.push(subgroup);
          }
          if (perm.id) {
            subgroup.key = Math.max(subgroup.key, Math.abs(perm.id));
          }
        }
      }
    });

    result.push({
      rowKey: permissionDef.name,
      permissionDef,
      actions: new Set(actions),
    });
    actionsByConditions.forEach((subgroup) => {
      result.push({
        rowKey: `${permissionDef.name}-${subgroup.key}`,
        permissionDef,
        conditions: subgroup.conditions,
        actions: new Set(subgroup.actions),
      });
    });
  });
  return {
    actions: appPermissionActions,
    permissions: result,
  };
}
