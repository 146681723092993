/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import PageLayout from 'layouts/PageLayout';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';

function CoverLayout({
  color = 'info',
  header = '',
  title = '',
  description = '',
  image,
  top = 3,
  children,
}: any) {
  return (
    <PageLayout background='white'>
      <Grid
        container
        justifyContent='center'
        sx={{
          minHeight: '75vh',
          margin: 0,
        }}
      >
        <Grid item xs={9} sm={7} md={5} xl={3}>
          <SoftBox mt={top}>
            <SoftBox pt={3} px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1} mr={-16}>
                    <SoftTypography
                      variant='h4'
                      fontWeight='bold'
                      color={color}
                      textGradient
                    >
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant='body2'
                    fontWeight='regular'
                    color='text'
                    noWrap
                    overflow='inherit'
                  >
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <SoftBox
            height='100%'
            display={{ xs: 'none', md: 'block' }}
            position='relative'
            right={{ md: '-12rem', xl: '-16rem' }}
            mr={-16}
            sx={{
              transform: 'skewX(-10deg)',
              overflow: 'hidden',
              borderBottomLeftRadius: ({ borders: { borderRadius } }: any) =>
                borderRadius.lg,
            }}
          >
            <SoftBox
              ml={-8}
              height='100%'
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                transform: 'skewX(10deg)',
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
