/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import StatusReport from 'models/reports/StatusReport';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BaseReportPage from './BaseReportPage';
import DatesForReportParameters from './filters/DatesForReportParameters';
import PeriodsFilterForReportParameters from './filters/PeriodsFilterForReportParameters';

const StatusReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'Status',
  } as StatusReport);
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Status Report'
      reportType={'Status'}
      parameters={parameters}
      onLoadParameters={setParameters}
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pb={3}>
          <Grid item xs={6} md={4} xl={3}>
            <SwitchWithLabel
              label='Period start date included into interval'
              tabIndex={1}
              checked={parameters.startDateIncluded || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, startDateIncluded: checked });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <SwitchWithLabel
              label='Period end date included into interval'
              tabIndex={1}
              checked={parameters.endDateIncluded || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, endDateIncluded: checked });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <PeriodsFilterForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          disabled={disabled}
          tabIndexForInputs={1}
        />
      </Grid>
    </BaseReportPage>
  );
};

export default StatusReportPage;
