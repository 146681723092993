/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Period from 'models/Period/Period';
import timesheetQueries from 'queries/TimesheetQueries';
import dataColumns from 'pages/Timesheet/dataColumns';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftTypography from 'softUI/components/SoftTypography';
import DataTable from './DataTable';

type PeriodTimesheetsCardProps = {
  period: Period;
  tabIndex: number;
  onAddSpreadOverTimesheets: () => void;
  readOnly: boolean;
};

const PeriodTimesheetsCard = ({
  period,
  tabIndex,
  onAddSpreadOverTimesheets,
  readOnly,
}: PeriodTimesheetsCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftBox display='flex' justifyContent='space-between'>
        <SoftTypography variant='h6' sx={{ pl: 1 }}>
          Timesheets
        </SoftTypography>
        <SoftBox>
          {!readOnly &&
            !!period.spreadOver &&
            period.id &&
            period.id > 0 &&
            onAddSpreadOverTimesheets && (
              <SoftButton
                size='small'
                color='info'
                tabIndex={tabIndex}
                onClick={onAddSpreadOverTimesheets}
              >
                Add SpreadOver Timesheets
              </SoftButton>
            )}
          {!readOnly && (
            <SoftButton
              component={Link}
              to={'/timesheets/new'}
              state={{ period: period }}
              tabIndex={tabIndex}
              size='small'
              color='info'
              sx={{ ml: 1 }}
            >
              Add Timesheet
            </SoftButton>
          )}
        </SoftBox>
      </SoftBox>
      <Box sx={{ pt: 1, width: '100%', height: 400 }}>
        <DataTable
          formatEditEntityPageUrl={useCallback(
            (id) => `/timesheets/edit/${id}`,
            [],
          )}
          localStorageItemName='periodTimesheetDataTableState'
          gridColumns={dataColumns}
          queriesImpl={timesheetQueries}
          filterForQuery={{ periodIds: [period.id] }}
        />
      </Box>
    </Card>
  );
};

export default PeriodTimesheetsCard;
