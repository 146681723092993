/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import isEqual from 'lodash/isEqual';
import RolePermission from 'models/RolePermission';
import PermissionConditions, {
  applyConditions,
  extractConditions,
} from './PermissionConditions';
import { PermissionActionDef, PermissionDef } from './appPermissions';

export function addPermissionConditions(
  permissions: RolePermission[],
  permissionDef: PermissionDef,
): RolePermission[] {
  let newId = -1;
  for (const perm of permissions) {
    if (permissionDef.resources.has(perm.resource) && perm.maxAgeDays === 0) {
      return permissions;
    }
    if (perm.id && perm.id < newId) {
      newId = perm.id - 1;
    }
  }

  return [
    ...permissions,
    ...[...permissionDef.resources].map(
      (resource) =>
        ({
          id: newId,
          resource,
          actions: ['READ'],
          maxAgeDays: 0,
        }) as RolePermission,
    ),
  ];
}

export function updatePermissionConditions(
  permissions: RolePermission[],
  permissionDef: PermissionDef,
  conditions: PermissionConditions,
  newConditions: PermissionConditions,
): RolePermission[] {
  if (isEqual(conditions, newConditions)) {
    return permissions;
  }

  return permissions
    .filter(
      (perm) =>
        !permissionDef.resources.has(perm.resource) ||
        !isEqual(extractConditions(perm), newConditions),
    )
    .map((perm) =>
      permissionDef.resources.has(perm.resource) &&
      isEqual(extractConditions(perm), conditions)
        ? applyConditions({ ...perm }, newConditions)
        : perm,
    );
}

export function updatePermissionAction(
  permissions: RolePermission[],
  permissionDef: PermissionDef,
  conditions: PermissionConditions | undefined,
  action: PermissionActionDef,
  checked: boolean,
): RolePermission[] {
  let newId = -1;
  const notFound = new Set(permissionDef.resources);
  const result = permissions
    .map((perm) => {
      if (perm.id && perm.id < newId) {
        newId = perm.id - 1;
      }

      if (notFound.has(perm.resource)) {
        const extracted = extractConditions(perm);
        if (
          (!conditions && !extracted) ||
          isEqual(extractConditions(perm), conditions)
        ) {
          notFound.delete(perm.resource);

          const updatedActions = checked
            ? [
                ...perm.actions,
                action.action,
                ...(action.activateActions || []),
              ]
            : perm.actions.filter(
                (act) =>
                  act !== action.action &&
                  !action.deactivateActions?.includes(act),
              );
          return {
            ...perm,
            actions: updatedActions,
          };
        }
      }

      return perm;
    })
    .filter((perm) => perm.actions.length);

  if (!notFound.size || !checked) {
    return result;
  } else {
    const newActions = [action.action, ...(action.activateActions || [])];
    return [
      ...result,
      ...[...notFound].map((resource) =>
        applyConditions(
          {
            id: newId,
            resource,
            actions: newActions,
          },
          conditions,
        ),
      ),
    ];
  }
}
