/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { AxiosHeaders } from 'axios';
import ChangePassword from 'models/ChangePassword';
import Profile from 'models/Profile';
import { DataWrapper } from './BaseWrappedListingQueries';
import http from './HttpClient';

class SecurityQueries {
  login(username: string, password: string, reCaptchaToken?: string) {
    const headers = new AxiosHeaders({
      Authorization: 'Basic ' + btoa(username + ':' + password),
    });
    if (reCaptchaToken) {
      headers.set('Captcha-Token', reCaptchaToken);
    }
    return http.get<DataWrapper<Profile>>('/profile', {
      headers: headers,
    });
  }

  verifyLogin() {
    return http.get<DataWrapper<Profile>>('/profile');
  }

  logout() {
    return http.post(`/logout`);
  }

  changePassword(payload: ChangePassword) {
    return http.post('/profile', payload);
  }
}

export default new SecurityQueries();

export function isCredentialsExpiredError(error: any) {
  const responseStatus = error.response?.status;
  const responseHeaders = error.response?.headers;
  return responseStatus === 401 && responseHeaders?.get('Expired-Credentials');
}
