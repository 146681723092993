/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid2 from '@mui/material/Grid2';
import moment from 'moment';
import AccessControlContextProvider from 'security/context/AccessControl/AccessControlContextProvider';
import { asUTC } from 'util/Formatters';
import Timesheet from 'models/Timesheet/Timesheet';
import getAvailability from 'models/Timesheet/getAvailability';
import timesheetQueries from 'queries/TimesheetQueries';
import ConfirmationDialog from 'components/ConfirmationDialog';
import BasePage, { BasicPageProps } from 'pages/BasePage';
import getLabels from 'pages/Period/getLabels';
import TimesheetInfoCard from './cards/TimesheetInfoCard';
import TimesheetNotesCard from './cards/TimesheetNotesCard';
import TimesheetPaymentsCard from './cards/TimesheetPaymentsCard';
import TimesheetRatesCard from './cards/TimesheetRatesCard';
import getPayments from './getPayments';
import validationHelper from './validationHelper';

const TimesheetPage = (props: BasicPageProps) => {
  const location = useLocation();
  const period = location.state?.period;

  const [invalidWedAlertOpen, setInvalidWedAlertOpen] = useState(false);
  const [invalidWedMessage, setInvalidWedMessage] = useState('');

  function createEmptyTimesheet(): Timesheet {
    return {
      id: -1,
      period: period,
      otBillRate: period?.otBillRate || 0,
      otPayRate: period?.otPayRate || 0,
      vendorManagement: period?.vendorManagement || 0,
      weekEndingDate: moment()
        .day('Sunday')
        .week(moment().week())
        .year(moment().year())
        .toDate(),
      straightTime: 0,
      overtime: 0,
      holidayTime: 0,
      vacationTime: 0,
      sickTime: 0,
      perDiem: 0,
      permFee: 0,
      fixedBill: period?.fixedBill,
      isBillFixed: period?.isBillFixed,
    };
  }

  function validateWED(timesheet: Timesheet, date?: Date) {
    const wed = date || new Date(timesheet.weekEndingDate);
    const period = timesheet.period;
    if (period?.startDate && period?.endDate) {
      const startDate = new Date(period.startDate);
      const endDate = new Date(period.endDate);
      if (
        wed.getTime() < startDate.getTime() ||
        wed.getTime() > endDate.getTime()
      ) {
        setInvalidWedMessage(
          'The specified Week Ending date is outside of the selected period.\n' +
            `It should be between ${asUTC(startDate)} and ${asUTC(endDate)}`,
        );
        setInvalidWedAlertOpen(true);
      }
    }
  }

  const ParameterizedBasePage = BasePage<Timesheet>;
  const TimesheetPage = (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyTimesheet}
      queriesImpl={timesheetQueries}
      validationHelper={validationHelper}
      getAvailability={getAvailability}
      getLabels={(t: Timesheet) => getLabels(t.period)}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Timesheet?'
      confirmRemovalDialogText='Are you sure you want to delete current Timesheet?'
      confirmSaveDialogText='Are you sure you want to save changes to current Timesheet?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Timesheet?'
      formatEditEntityPageUrl={(id) => `/timesheets/edit/${id}`}
      allowEmptySave={true}
    >
      {(sectionParams) => {
        const timesheet = sectionParams.editedData;
        const availability = sectionParams.availability;
        const labels = sectionParams.labels;
        const tabIndex = sectionParams.tabIndexForInputs;
        const errors = sectionParams.validationErrors;
        const updateInputField = sectionParams.updateInputField;
        const updateField = sectionParams.updateField;
        const payments = getPayments(timesheet);

        useEffect(() => validateWED(timesheet), [timesheet.period]);

        return (
          <Grid2 container spacing={2}>
            <Grid2
              container
              size={{ xs: 12, lg: 9 }}
              columnSpacing={0}
              rowSpacing={2}
              sx={{ flexDirection: 'column' }}
            >
              <Grid2 size={{ xs: 12 }}>
                <TimesheetInfoCard
                  timesheet={timesheet}
                  availability={availability}
                  tabIndex={tabIndex}
                  errors={errors}
                  validateWED={validateWED}
                  updateField={updateField}
                  updateInputField={updateInputField}
                  readOnly={sectionParams.readOnly}
                />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <TimesheetNotesCard
                  timesheet={timesheet}
                  tabIndex={tabIndex}
                  errors={errors}
                  updateInputField={updateInputField}
                  readOnly={sectionParams.readOnly}
                />
              </Grid2>
            </Grid2>
            <Grid2
              container
              size={{ xs: 12, lg: 3 }}
              columnSpacing={0}
              rowSpacing={2}
              sx={{ flexDirection: 'column' }}
            >
              <Grid2 size={{ xs: 12 }}>
                <TimesheetRatesCard period={timesheet.period} labels={labels} />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <TimesheetPaymentsCard payments={payments} />
              </Grid2>
            </Grid2>
            <ConfirmationDialog
              open={invalidWedAlertOpen}
              title='WED validation'
              description={invalidWedMessage}
              yesButtonCaption='OK'
              disableNoButton={true}
              resultCallback={() => setInvalidWedAlertOpen(false)}
            />
          </Grid2>
        );
      }}
    </ParameterizedBasePage>
  );

  return (
    <AccessControlContextProvider resource='TIMESHEETS'>
      {TimesheetPage}
    </AccessControlContextProvider>
  );
};

export default TimesheetPage;
