/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useRef } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { getErrorMessage } from 'queries/ErrorResponse';
import importQueries, { ExportImportType } from 'queries/ExportImportQueries';
import ResponsiveButton from './ResponsiveButton';

type EntityImporterProps = {
  importType: ExportImportType;
  onProgressBackdropChange: (isOpen: boolean) => void;
  onNotificationChange: (
    severity: AlertColor,
    message: string,
    progressPercentage?: number,
  ) => void;
  tabIndex?: number;
};

const EntityImporter = (props: EntityImporterProps) => {
  function resetProgressBackdrop() {
    props.onProgressBackdropChange(false);
  }

  const importEntities = async (fileList: File[]) => {
    try {
      props.onProgressBackdropChange(true);
      props.onNotificationChange('info', 'Uploading...');

      const importProgress = await importQueries.initiateImport(
        props.importType,
        fileList,
      );
      pollAndThenDownloadImportResult(importProgress.id);
    } catch (error: any) {
      props.onNotificationChange(
        'error',
        await getErrorMessage(error, 'Upload failed'),
      );
      resetProgressBackdrop();
    }
  };

  const pollAndThenDownloadImportResult = (id: string) => {
    const pollStartTime = Date.now();
    const generateResultMaxTimeout = 1800000;
    props.onNotificationChange('info', 'Importing...');

    const timerId = setInterval(async () => {
      if (Date.now() - pollStartTime > generateResultMaxTimeout) {
        clearInterval(timerId);
        props.onNotificationChange(
          'error',
          `Timeout is reached, the import result is still not ready.`,
        );
        resetProgressBackdrop();
        return;
      }

      try {
        const importProgress = await importQueries.getImportProgress(
          props.importType,
          id,
        );
        if (!importProgress.completed) {
          props.onNotificationChange(
            'info',
            'Importing...',
            importProgress.progressPercent || 0,
          );
        } else if (importProgress.hasResults) {
          clearInterval(timerId);
          downloadImportResult(id);
        } else {
          clearInterval(timerId);
          props.onNotificationChange(
            'error',
            `Import is completed with a failure / no result. Details: ${importProgress.failureDetails}`,
          );
          resetProgressBackdrop();
        }
      } catch (error: any) {
        clearInterval(timerId);
        props.onNotificationChange(
          'error',
          await getErrorMessage(error, 'Failed to obtain the import result'),
        );
        resetProgressBackdrop();
      }
    }, 2000);
  };

  function buildResultFileName() {
    const type = props.importType;
    const entityName = type.charAt(0).toUpperCase() + type.slice(1);
    const currentDate = moment().format('YYYY-MM-DD');
    return `ImportResults-${entityName}-${currentDate}.xlsx`;
  }

  const downloadImportResult = async (id: string) => {
    props.onNotificationChange('info', 'Downloading the import result ...');

    try {
      const resultData = await importQueries.getImportResult(
        props.importType,
        id,
      );
      saveAs(resultData, buildResultFileName());
    } catch (error: any) {
      props.onNotificationChange(
        'error',
        await getErrorMessage(error, 'Failed to download the import result'),
      );
    }

    resetProgressBackdrop();
    props.onNotificationChange('info', '');
  };

  const importInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <ResponsiveButton
        label='Import'
        color='info'
        icon='upload'
        tabIndex={props.tabIndex}
        onClick={() => importInputRef.current && importInputRef.current.click()}
      />
      <input
        ref={importInputRef}
        type='file'
        accept='.xls,.xlsx'
        hidden
        multiple
        onChange={(e) => {
          const files = e.target.files;
          importEntities(files ? Array.from(files) : []);
          e.target.value = '';
        }}
        tabIndex={-1}
      />
    </>
  );
};

export default EntityImporter;
