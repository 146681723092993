/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import WeeklyOfficeReport from 'models/reports/WeeklyOfficeReport';
import locationQueries from 'queries/LocationQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import DatesForReportParameters from './filters/DatesForReportParameters';

const validationHelper = new ValidationHelper<WeeklyOfficeReport>(
  Yup.object({
    locationIds: Yup.array().required('At least one Location must be present'),
  }),
);

const WeeklyOfficeReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'WeeklyOffice',
  } as WeeklyOfficeReport);
  const [validationErrors, setValidationErrors] = useState(
    {} as ValidationErrors<WeeklyOfficeReport>,
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Weekly Office Report'
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pt={3}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Locations'
              tabIndex={1}
              optionsFetcher={locationQueries}
              optionFilter={(l) => l.isActive}
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  locationIds: ids || [],
                });
              }}
              disabled={disabled}
              error={validationErrors?.locationIds?.join(';')}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={6}>
            <SwitchWithLabel
              label='Show all locations on a single sheet'
              tabIndex={1}
              checked={parameters.combinedReport || false}
              onChange={(_, checked) => {
                setParameters({ ...parameters, combinedReport: checked });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default WeeklyOfficeReportPage;
