/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { saveAs } from 'file-saver';
import { getErrorMessage } from 'queries/ErrorResponse';
import reportQueries from 'queries/ReportQueries';

const downloadReport = async (
  reportId: number,
  fileName: string,
  errorMessageHandler?: (message: string) => void,
  onCompleteCallback?: () => any,
) => {
  try {
    const reportData = await reportQueries.getData(reportId);
    saveAs(reportData, fileName);
  } catch (error: any) {
    if (errorMessageHandler) {
      errorMessageHandler(
        await getErrorMessage(
          error,
          'Failed to download generated report data',
        ),
      );
    }
  }
  onCompleteCallback?.();
};

export default downloadReport;
