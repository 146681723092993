/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import ReportConfig from 'models/ReportConfig';
import ReportType from 'models/ReportType';
import http from './HttpClient';

class ReportConfigQueries {
  getConfigs(type: ReportType): UseQueryResult<ReportConfig[]> {
    const url = `reportconfigs/${type}`;
    return useQuery({
      queryKey: [url],
      queryFn: async () => {
        if (type) {
          const response = await http.get<ReportConfig[]>(url);
          return response.data;
        }
        return [];
      },

      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      placeholderData: keepPreviousData,
    });
  }

  create(type: ReportType): UseMutationResult<any, any, ReportConfig, any> {
    const url = `reportconfigs/${type}`;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (config: ReportConfig) => {
        return http.post(url, config);
      },
      onSuccess: () => queryClient.removeQueries({ queryKey: [url] }),
    });
  }

  delete(type: ReportType): UseMutationResult<any, any, number, any> {
    const url = `reportconfigs/${type}`;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (configId: number) => {
        return http.delete(`${url}/${configId}`);
      },
      onSuccess: () => queryClient.removeQueries({ queryKey: [url] }),
    });
  }
}

export default new ReportConfigQueries();
