/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Icon from '@mui/material/Icon';
import isEqual from 'lodash/isEqual';
import { formatDate } from 'util/Formatters';
import SoftDatePicker from 'softUI/components/SoftDatePicker';
import SoftInput from 'softUI/components/SoftInput';
import BaseLabeledComponent from './BaseLabeledComponent';

type DateWithLabelProps = {
  label: string;
  value: any;
  error?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  onChange: (date?: Date) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

const DateWithLabel = (props: DateWithLabelProps) => {
  let dirty = false;

  const value = props.value && formatDate(new Date(props.value), 'MM/DD/YYYY');
  return (
    <BaseLabeledComponent label={props.label} error={props.error}>
      {!props.readOnly ? (
        <SoftDatePicker
          input={{
            size: 'small',
            autoFocus: props.autoFocus,
            disabled: props.disabled,
            placeholder: 'mm/dd/yyyy',
            icon: {
              component: <Icon>calendar_month</Icon>,
              direction: 'right',
            },
            // A workaround for missed onChange in the case of mouse click outside the input.
            onBlur: (e: any) => {
              if (dirty) {
                props.onChange(e.target.value && new Date(e.target.value));
                dirty = false;
              }
            },
            onChange: () => {
              dirty = true;
            },
            sx: {
              minHeight: '1.932rem', // A workaround to align the height with other input controls.
            },
          }}
          inputTabIndex={props.tabIndex}
          value={value}
          onChange={(newDate: any) => {
            // Flatpickr gives empty array in a case of no date set.
            if (Array.isArray(newDate) && !newDate.length) {
              props.onChange(undefined);
            } else {
              props.onChange(newDate && new Date(newDate));
            }
            dirty = false;
          }}
          options={{
            dateFormat: 'm/d/Y',
            allowInput: !props.disabled,
            clickOpens: !props.disabled,
          }}
        />
      ) : (
        <SoftInput
          size='small'
          variant='outlined'
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          value={value || ''}
          slotProps={{
            input: {
              tabIndex: props.tabIndex,
            },
          }}
        />
      )}
    </BaseLabeledComponent>
  );
};

export default memo(
  DateWithLabel,
  (before, after) =>
    before.label === after.label &&
    isEqual(before.value, after.value) &&
    before.error === after.error &&
    before.tabIndex === after.tabIndex &&
    before.autoFocus === after.autoFocus &&
    before.onChange === after.onChange &&
    before.disabled === after.disabled &&
    before.readOnly === after.readOnly,
) as typeof DateWithLabel;
