/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  HTMLAttributes,
  HTMLInputTypeAttribute,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Chip,
  FilterOptionsState,
  SxProps,
  Theme,
  createFilterOptions,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import BaseLabeledComponent, { prefixWithLabel } from './BaseLabeledComponent';
import OptionsFetcher from './OptionsFetcher';

type FreeValue<FreeValueFlag extends boolean> = FreeValueFlag extends true
  ? string
  : never;

type AutocompleteWithLabelProps<
  OptionValue,
  OptionKey,
  FreeValueFlag extends boolean,
> = {
  autoFocus?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  disableListWrap?: boolean;
  error?: string;
  freeValues?: FreeValueFlag;
  inputType?: HTMLInputTypeAttribute;
  isOptionEqualTo?: (option1: OptionValue, option2: OptionValue) => boolean;
  label: string;
  limitSelection?: number;
  limitTags?: number;
  onChange: (
    selectedValues: (OptionValue | FreeValue<FreeValueFlag>)[],
    selectedIds: OptionKey[],
  ) => void;
  optionFilter?: (option: OptionValue) => boolean;
  optionIdGetter?: (option: OptionValue) => OptionKey;
  optionLabelGetter?: (option: OptionValue) => string | undefined;
  optionsFetcher?:
    | OptionsFetcher<OptionValue>
    | BaseFetchingQueries<OptionValue>;
  passResetFunction?: (resetFunction: () => void) => void;
  readOnly?: boolean;
  selectAll?: boolean;
  selectAllOption?: OptionValue;
  selectAllLabel?: string;
  selectedValues?: (OptionValue | FreeValue<FreeValueFlag>)[];
  sx?: SxProps<Theme>;
  tabIndex?: number;
};

const AutocompleteWithLabel = <
  OptionValue,
  OptionKey = number,
  FreeValueFlag extends boolean = false,
>({
  disableCloseOnSelect = true,
  inputType = 'text',
  // @ts-ignore
  isOptionEqualTo = (option1, option2) => option1?.id === option2?.id,
  // @ts-ignore
  optionIdGetter = (option) => option.id,
  // @ts-ignore
  optionLabelGetter = (option) => option.name,
  selectAll = true,
  selectAllOption = {
    id: -1,
  } as OptionValue,
  selectAllLabel = 'All',
  ...props
}: AutocompleteWithLabelProps<OptionValue, OptionKey, FreeValueFlag>) => {
  // Options - the source.
  const { isFetching, isSuccess, data, refetch } =
    (props.optionsFetcher &&
      ('getAll' in props.optionsFetcher
        ? props.optionsFetcher.getAll()
        : props.optionsFetcher())) ||
    {};

  // Options - filter & extract the labels.
  const [options, optionLabels] = useMemo(() => {
    if (isSuccess && data) {
      const filtered = props.optionFilter
        ? data.filter(props.optionFilter)
        : data;
      const labels = new Map<any, string>(
        filtered.map((option) => [
          optionIdGetter(option),
          optionLabelGetter(option) || '',
        ]),
      );
      return [filtered, labels];
    } else {
      return [[], new Map()];
    }
  }, [isSuccess, data, props.optionFilter, optionIdGetter, optionLabelGetter]);

  // Options - prefetch to be able to show the selected option labels.
  const requiresPrefetch = useMemo(
    () =>
      !optionLabels.size &&
      props.selectedValues?.some(
        (v) => typeof v === 'object' && optionLabelGetter(v) === undefined,
      ),
    [optionLabels, props.selectedValues, optionLabelGetter],
  );
  const fetchOptions = () => !isSuccess && refetch?.();
  if (requiresPrefetch) fetchOptions();

  // Options - add SelectAll option.
  const filterOptions = createFilterOptions<OptionValue>();
  const addSelectAllOption = (
    options: OptionValue[],
    params: FilterOptionsState<OptionValue>,
  ) => {
    const filtered = filterOptions(options, params);
    if (
      !selectAll ||
      !!inputValue ||
      !options.length ||
      (props.limitSelection && props.limitSelection < options.length)
    ) {
      return filtered;
    } else {
      return [selectAllOption, ...filtered];
    }
  };

  // Options - disable if the limit reached.
  const disableOptionsWhenLimitReached = (option: OptionValue) => {
    return props.limitSelection && selected.length >= props.limitSelection
      ? !selected.includes(option)
      : false;
  };

  // Options - equal function for marking selected options.
  const isOptionEqualToValue = (option1: OptionValue, option2: OptionValue) => {
    return (
      option1 === option2 ||
      (typeof option1 === 'object' &&
        typeof option2 === 'object' &&
        isOptionEqualTo(option1, option2))
    );
  };

  // Selected - the intermediate state while the select box is open. Changes are to be propagated once the box is closed.
  const [selected, setSelected] = useState(
    [] as (OptionValue | FreeValue<FreeValueFlag>)[],
  );

  // Selected - propagate changed selectedValues.
  useEffect(() => {
    setSelected(props.selectedValues || []);
  }, [props.selectedValues]);

  // Selected - callback to reset the state.
  props.passResetFunction?.(() => setSelected([]));

  // Selected - sieve non-existent options.
  useEffect(() => {
    if (selected.length && optionLabels.size) {
      const sievedValues = selected.filter(
        (v) => typeof v !== 'object' || optionLabels.has(optionIdGetter(v)),
      );
      if (selected.length != sievedValues.length) {
        setSelected(sievedValues);
        callOnChange(sievedValues);
      }
    }
  }, [optionLabels]);

  // Selected - are all selected?
  const areAllSelected = () => selectAll && options.length === selected.length;

  // Selected - on change event handler.
  const onChangeHandler = (
    event: any,
    value: (OptionValue | FreeValue<FreeValueFlag>)[],
    reason: string,
  ) => {
    const isCreate = reason === 'createOption';
    if (isCreate) {
      setInputValue('');
    }
    if (reason === 'selectOption' || reason === 'removeOption' || isCreate) {
      if (value.includes(selectAllOption)) {
        if (!areAllSelected()) {
          setSelected(options);
        } else {
          setSelected([]);
        }
      } else {
        setSelected(value);
      }
      // clicked on deleteIcon of a chip/tag
      if (['path', 'svg'].includes(event.target.tagName)) {
        callOnChange(value);
      }
    } else if (reason === 'clear') {
      setSelected([]);
      callOnChange([]);
    }
  };

  // Selected - propagate changes.
  const callOnChange = (values: (OptionValue | FreeValue<FreeValueFlag>)[]) => {
    const ids = values
      .filter((v) => typeof v === 'object')
      .map((v) => optionIdGetter(v as OptionValue))
      .filter((id) => typeof id !== 'undefined');
    props.onChange(values, ids);
  };

  // Option render - the label resolver.
  const getOptionLabel = (
    option: OptionValue | FreeValue<FreeValueFlag>,
  ): string => {
    if (typeof option === 'string') {
      return option;
    } else if (option === selectAllOption) {
      return selectAllLabel;
    } else if (optionLabels.size) {
      const result = optionLabels.get(optionIdGetter(option));
      if (result) {
        return result;
      }
    }
    return optionLabelGetter(option) ?? 'Loading...';
  };

  // Option render - the component builder.
  const renderOption = (
    props: HTMLAttributes<HTMLLIElement> & { key: any },
    option: OptionValue,
    { selected }: AutocompleteRenderOptionState,
  ) => (
    <Typography
      component='li'
      {...props}
      noWrap
      // eslint-disable-next-line react/prop-types
      key={props.key}
    >
      <Checkbox
        color='primary'
        style={{ marginRight: 8 }}
        checked={option === selectAllOption ? areAllSelected() : selected}
      />
      {getOptionLabel(option)}
    </Typography>
  );

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  // Input - the state.
  const [inputValue, setInputValue] = useState('');
  // Input - the renderer.
  const renderInput = (params: any) => (
    <TextField
      {...params}
      type={inputType}
      placeholder={
        props.disabled || props.readOnly
          ? ''
          : props.freeValues
            ? 'Type here'
            : 'Type to search'
      }
      autoFocus={props.autoFocus}
      inputProps={{
        ...params.inputProps,
        tabIndex: props.tabIndex,
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      sx={{
        '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
          display: props.readOnly ? 'none' : '',
        },
        maxHeight: '6rem',
        '.MuiInputBase-root': {
          overflowY: 'auto',
        },
      }}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => setInputValue('')}
    />
  );

  return (
    <BaseLabeledComponent label={props.label} error={props.error} sx={props.sx}>
      <Autocomplete
        autoSelect={props.freeValues}
        disableClearable={props.disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={props.disabled}
        disableListWrap={props.disableListWrap}
        filterOptions={addSelectAllOption}
        freeSolo={props.freeValues}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={disableOptionsWhenLimitReached}
        id={prefixWithLabel(props.label, 'autocomplete')}
        inputValue={inputValue}
        isOptionEqualToValue={isOptionEqualToValue}
        limitTags={props.limitTags || -1}
        loading={isFetching}
        multiple
        options={options}
        onOpen={fetchOptions}
        onChange={onChangeHandler}
        onClose={() => callOnChange(selected)}
        PopperComponent={StyledPopper}
        renderOption={renderOption}
        renderInput={renderInput}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={(optionIdGetter(option) || option) as string}
              label={getOptionLabel(option)}
            />
          ));
        }}
        readOnly={props.readOnly}
        size='small'
        value={selected}
      />
    </BaseLabeledComponent>
  );
};

export default memo(
  AutocompleteWithLabel,
  (before, after) =>
    before.autoFocus === after.autoFocus &&
    before.disableClearable === after.disableClearable &&
    before.disableCloseOnSelect === after.disableCloseOnSelect &&
    before.disabled === after.disabled &&
    before.disableListWrap === after.disableListWrap &&
    before.error === after.error &&
    before.freeValues === after.freeValues &&
    before.inputType === after.inputType &&
    before.isOptionEqualTo === after.isOptionEqualTo &&
    before.label === after.label &&
    before.limitSelection === after.limitSelection &&
    before.limitTags === after.limitTags &&
    before.onChange === after.onChange &&
    before.optionFilter === after.optionFilter &&
    before.optionIdGetter === after.optionIdGetter &&
    before.optionLabelGetter === after.optionLabelGetter &&
    before.optionsFetcher === after.optionsFetcher &&
    // expecting functions to be wrapped with 'useCallback'
    before.passResetFunction === after.passResetFunction &&
    before.readOnly === after.readOnly &&
    before.selectAll === after.selectAll &&
    isEqual(before.selectAllOption, after.selectAllOption) &&
    before.selectAllLabel === after.selectAllLabel &&
    isEqual(before.selectedValues, after.selectedValues) &&
    isEqual(before.sx, after.sx) &&
    before.tabIndex === after.tabIndex,
) as typeof AutocompleteWithLabel;
