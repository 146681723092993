/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import TableRow from '@mui/material/TableRow';
import pxToRem from 'softUI/assets/theme/functions/pxToRem';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';
import { PermissionActionDef } from './appPermissions';

export default function TableHeaderWithActions({
  actions,
}: {
  actions: PermissionActionDef[];
}) {
  return (
    <SoftBox component='thead'>
      <TableRow>
        <ColumnHeader align='left' sx={{ pl: 1, pb: 0.5 }}>
          Resource
        </ColumnHeader>
        {actions.map((action) => (
          <ColumnHeader
            key={action.action}
            align='center'
            width={pxToRem(80)}
            sx={{ pb: 0.5 }}
          >
            {action.name}
          </ColumnHeader>
        ))}
      </TableRow>
    </SoftBox>
  );
}

function ColumnHeader({ children, ...rest }: any) {
  return (
    <SoftBox component='th' {...rest}>
      <SoftTypography
        variant='caption'
        fontWeight='bold'
        color='secondary'
        textTransform='uppercase'
        noWrap
      >
        {children}
      </SoftTypography>
    </SoftBox>
  );
}
