/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import RolePermission from './RolePermission';
import SimpleModel from './SimpleModel';

export default interface UserRole extends SimpleModel {
  isAdministrator: boolean;
  permissions: RolePermission[];
}

export function isReporter(role: UserRole) {
  return !!role.permissions.find(
    (permission) => permission.resource === 'REPORTS',
  );
}
