/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
import { asUTC, formatDate } from 'util/Formatters';
import pxToRem from 'softUI/assets/theme/functions/pxToRem';
import SoftBox from 'softUI/components/SoftBox';
import SoftDatePicker from 'softUI/components/SoftDatePicker';
import SoftInput from 'softUI/components/SoftInput';
import PermissionConditions from './PermissionConditions';

export default function PermissionDateConditions({
  value,
  onChange,
  tabIndex,
}: {
  value: PermissionConditions;
  onChange: (value: PermissionConditions) => void;
  tabIndex: number;
}) {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [maxAgeDays, setMaxAgeDays] = useState<number>();
  // Selected - propagate changed the value.
  useEffect(() => {
    setStartDate(
      value.startDate && formatDate(new Date(value.startDate), 'MM/DD/YYYY'),
    );
    setEndDate(
      value.endDate && formatDate(new Date(value.endDate), 'MM/DD/YYYY'),
    );
    setMaxAgeDays(value.maxAgeDays);
  }, [value]);

  const onMaxAgeDaysChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let newValueAsNumber: number | undefined = undefined;
    if (!isEmpty(e.target.value)) {
      newValueAsNumber = +e.target.value;
      if (isNaN(newValueAsNumber)) {
        newValueAsNumber = undefined;
      }
    }
    setMaxAgeDays(newValueAsNumber);
  };

  const onMaxAgeDaysBlurHandler = () => {
    if (maxAgeDays === undefined || isNaN(maxAgeDays) || maxAgeDays < 0) {
      onChange({ maxAgeDays: 0 });
    } else {
      onChange({ maxAgeDays });
    }
  };

  const option = value.maxAgeDays !== undefined ? 'MAX_AGE' : 'WITHIN';
  return (
    <SoftBox display='flex' minWidth={option === 'MAX_AGE' ? '260px' : '420px'}>
      <Select
        value={option}
        tabIndex={tabIndex}
        onChange={(e) => {
          if (e.target.value == 'MAX_AGE') {
            onChange({ maxAgeDays: value.maxAgeDays || 0 });
          } else {
            onChange({
              startDate: value.startDate,
              endDate: value.endDate || (asUTC(new Date()) as string),
            });
          }
        }}
        sx={{
          flex: '0 0 200px',
          ml: 1,
          padding: '0px !important',
          height: `${pxToRem(32)} !important`,
          '.MuiSelect-select': {
            width: '100% !important',
            padding: `${pxToRem(4)} ${pxToRem(26)}
                      ${pxToRem(4)} ${pxToRem(12)} !important`,
          },
        }}
      >
        <MenuItem value='MAX_AGE'>Not older than (days)</MenuItem>
        <MenuItem value='WITHIN'>Within dates</MenuItem>
      </Select>
      {option === 'MAX_AGE' ? (
        <SoftInput
          size='small'
          variant='outlined'
          value={maxAgeDays ?? ''}
          onChange={onMaxAgeDaysChangeHandler}
          onBlur={onMaxAgeDaysBlurHandler}
          onKeyPress={(e: KeyboardEvent) => {
            if (!/[0-9]/.test(e.key)) {
              // otherwise Mozilla allows to enter any character when input is empty ignoring onChange event
              e.preventDefault();
            }
          }}
          slotProps={{
            input: {
              tabIndex: tabIndex,
            },
          }}
          sx={{
            flex: '0 0 60px !important',
            ml: 1,
          }}
        />
      ) : (
        <>
          <SoftDatePicker
            input={{
              size: 'small',
              placeholder: 'Start Date',
              sx: {
                minHeight: '1.932rem', // A workaround to align the height with other input controls.
                ml: 1,
                flex: '0 0 110px',
              },
            }}
            inputTabIndex={tabIndex}
            value={startDate}
            onChange={(newDate: any) => {
              if (Array.isArray(newDate)) {
                setStartDate('');
                // Flatpickr gives empty array in a case of no date set.
                if (!newDate.length) {
                  onChange({
                    endDate: value.endDate || (asUTC(new Date()) as string),
                  });
                } else {
                  onChange({
                    ...value,
                    startDate: asUTC(newDate[0]) as string,
                  });
                }
              }
            }}
            options={{
              dateFormat: 'm/d/Y',
            }}
          />
          <SoftDatePicker
            input={{
              size: 'small',
              placeholder: 'End Date',
              sx: {
                minHeight: '1.932rem', // A workaround to align the height with other input controls.
                ml: 1,
                flex: '0 0 110px',
              },
            }}
            inputTabIndex={tabIndex}
            value={endDate}
            onChange={(newDate: any) => {
              if (Array.isArray(newDate)) {
                // Flatpickr gives empty array in a case of no date set.
                if (!newDate.length) {
                  setEndDate('');
                  if (value.startDate) {
                    setEndDate('');
                    onChange({
                      startDate: value.startDate,
                    });
                  } else {
                    onChange({
                      endDate: asUTC(new Date()) as string,
                    });
                  }
                } else {
                  onChange({ ...value, endDate: asUTC(newDate[0]) as string });
                }
              }
            }}
            options={{
              dateFormat: 'm/d/Y',
            }}
          />
        </>
      )}
    </SoftBox>
  );
}
