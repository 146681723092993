/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { getErrorMessage } from 'queries/ErrorResponse';
import exportQueries, { ExportImportType } from 'queries/ExportImportQueries';
import ConfirmationDialog from './ConfirmationDialog';
import ResponsiveButton from './ResponsiveButton';

type EntityExporterProps = {
  exportType: ExportImportType;
  exportFilter?: any;
  onProgressBackdropChange: (isOpen: boolean) => void;
  onNotificationChange: (
    severity: AlertColor,
    message: string,
    progressPercentage?: number,
  ) => void;
  confirmationDialogText?: string;
  tabIndex?: number;
};

const EntityExporter = (props: EntityExporterProps) => {
  function resetProgressBackdrop() {
    props.onProgressBackdropChange(false);
  }

  const exportEntities = async () => {
    try {
      props.onProgressBackdropChange(true);
      props.onNotificationChange('info', 'Exporting...');

      const exportProgress = await exportQueries.initiateExport(
        props.exportType,
        props.exportFilter,
      );
      pollAndThenDownloadExportResult(exportProgress.id);
    } catch (error: any) {
      props.onNotificationChange(
        'error',
        await getErrorMessage(error, 'Export failed'),
      );
      resetProgressBackdrop();
    }
  };

  const pollAndThenDownloadExportResult = (id: string) => {
    const pollStartTime = Date.now();
    const generateResultMaxTimeout = 1800000;

    const timerId = setInterval(async () => {
      if (Date.now() - pollStartTime > generateResultMaxTimeout) {
        clearInterval(timerId);
        props.onNotificationChange(
          'error',
          `Timeout is reached, the export result is still not ready.`,
        );
        resetProgressBackdrop();
        return;
      }

      try {
        const exportProgress = await exportQueries.getExportProgress(
          props.exportType,
          id,
        );
        if (!exportProgress.completed) {
          props.onNotificationChange(
            'info',
            'Exporting...',
            exportProgress.progressPercent || 0,
          );
        } else if (exportProgress.hasResults) {
          clearInterval(timerId);
          downloadExportResult(id);
        } else {
          clearInterval(timerId);
          props.onNotificationChange(
            'error',
            `Export is completed with a failure / no result. Details: ${exportProgress.failureDetails}`,
          );
          resetProgressBackdrop();
        }
      } catch (error: any) {
        clearInterval(timerId);
        props.onNotificationChange(
          'error',
          await getErrorMessage(error, 'Failed to obtain the export result'),
        );
        resetProgressBackdrop();
      }
    }, 2000);
  };

  function buildResultFileName() {
    const type = props.exportType;
    const entityName = type.charAt(0).toUpperCase() + type.slice(1);
    const currentDate = moment().format('YYYY-MM-DD');
    return `${entityName}-${currentDate}.xlsx`;
  }

  const downloadExportResult = async (id: string) => {
    props.onNotificationChange('info', 'Downloading the export result ...');

    try {
      const resultData = await exportQueries.getExportResult(
        props.exportType,
        id,
      );
      saveAs(resultData, buildResultFileName());
    } catch (error: any) {
      props.onNotificationChange(
        'error',
        await getErrorMessage(error, 'Failed to download the export result'),
      );
    }

    resetProgressBackdrop();
    props.onNotificationChange('info', '');
  };

  function onConfirmExportDialogResult(isConfirmed: boolean) {
    setConfirmExportDialogIsOpen(false);
    if (isConfirmed) {
      exportEntities();
    }
  }

  const [confirmExportDialogIsOpen, setConfirmExportDialogIsOpen] =
    useState(false);

  return (
    <>
      <ResponsiveButton
        label='Export'
        color='info'
        icon='download'
        tabIndex={props.tabIndex}
        onClick={() => {
          setConfirmExportDialogIsOpen(true);
        }}
      />
      <ConfirmationDialog
        open={confirmExportDialogIsOpen}
        title='Export'
        description={
          props.confirmationDialogText ||
          'Do you confirm export of all entities?'
        }
        resultCallback={onConfirmExportDialogResult}
      />
    </>
  );
};

export default EntityExporter;
