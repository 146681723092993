/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Icon from '@mui/material/Icon';
import AccessControlContextProvider from 'security/context/AccessControl/AccessControlContextProvider';
import Location from 'models/Location';
import { ExportImportType } from 'queries/ExportImportQueries';
import locationQueries from 'queries/LocationQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage';

const LocationsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'locationDataTableState',
    addNewEntityPageUrl: '/locations/new',
    formatEditEntityPageUrl(id) {
      return `/locations/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Locations?`;
      }
      return `Are you sure you want to remove selected Location with ID ${id}?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all found'
      } Locations?`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 117px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportImportType: ExportImportType.Locations,
    queriesImpl: locationQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'abbreviation', headerName: 'Abbreviation', width: 100 },
      {
        field: 'active',
        headerName: 'Active',
        width: 80,
        type: 'boolean',
        renderCell(params) {
          const location = params.row as Location;
          return location.isActive ? (
            <Icon fontSize='medium' color='success'>
              check
            </Icon>
          ) : (
            <Icon fontSize='medium' color='error'>
              close
            </Icon>
          );
        },
      },
      { field: 'notes', headerName: 'Notes', flex: 1 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return (
    <AccessControlContextProvider resource='LOCATIONS'>
      <BaseListPage {...properties} />
    </AccessControlContextProvider>
  );
};

export default LocationsPage;
