/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Period from 'models/Period/Period';

const validationHelper = new ValidationHelper<Period>(
  Yup.object({
    startDate: Yup.date()
      .typeError('Must be a valid date')
      .required('Must be present'),
    endDate: Yup.date()
      .typeError('Must be a valid date')
      .required('Must be present'),
    poNumber: Yup.string().optional().max(50, 'Must not exceed 50 chars'),
    client: Yup.object().required('Must be present'),
    consultant: Yup.object().required('Must be present'),
    invoicingFrequency: Yup.object().required('Must be present'),
    payPeriod: Yup.object().when('employmentType.family.id', {
      is: 1, // DH
      then: (schema) => schema.optional(),
      otherwise: (schema) => schema.required('Must be present'),
    }),
    pool: Yup.object().required('Must be present'),
    status: Yup.object().required('Must be present'),
    employmentType: Yup.object().required('Must be present'),
    placementNumber: Yup.string()
      .optional()
      .max(30, 'Must not exceed 30 chars'),
    vendorName: Yup.string().optional().max(50, 'Must not exceed 50 chars'),
    jobSite: Yup.string().optional().max(50, 'Must not exceed 50 chars'),
    candidateSource: Yup.string()
      .optional()
      .max(50, 'Must not exceed 50 chars'),
    billRatePermFee: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .max(99999999.99, 'Must not exceed 99999999.99')
      .when('employmentType.family.id', {
        is: 1, // DH
        then: (schema) =>
          schema.min(-99999999.99, 'Must not be less than -99999999.99'),
        otherwise: (schema) => schema.min(0, 'Must be positive value'),
      }),
    payRateBaseSalary: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    otBillRate: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    otPayRate: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    fixedBill: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    vendorManagement: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(999.99, 'Must not exceed 999.99'),
    perDiem: Yup.number()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(99999999.99, 'Must not exceed 99999999.99'),
    guaranteePeriod: Yup.number()
      .integer()
      .optional()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .max(9999, 'Must not exceed 9999'),
    spreadOver: Yup.number()
      .integer()
      .transform((_, val) => (val === Number(val) ? val : 0))
      .min(0, 'Must be positive value')
      .when('employmentType.family.id', {
        is: 1, // DH
        then: (schema) => schema.required('Must be present'),
        otherwise: (schema) => schema.optional(),
      }),
    accountExecutives: Yup.array().of(
      Yup.object().shape({
        employee: Yup.object().required('Employee must be present'),
        percents: Yup.number().required('Percent must be present'),
      }),
    ),
    recruiters: Yup.array().of(
      Yup.object().shape({
        employee: Yup.object().required('Employee must be present'),
        percents: Yup.number().required('Percent must be present'),
      }),
    ),
    locations: Yup.array().of(
      Yup.object().shape({
        location: Yup.object().required('Location must be present'),
        percents: Yup.number().required('Percent must be present'),
      }),
    ),
  }),
);

export default validationHelper;
