/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AccessControlContextProvider from 'security/context/AccessControl/AccessControlContextProvider';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Consultant from 'models/Consultant';
import consultantQueries from 'queries/ConsultantQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<Consultant>(
  Yup.object({
    firstName: Yup.string()
      .required('Must be present')
      .max(30, 'Must not exceed 30 chars'),
    lastName: Yup.string()
      .required('Must be present')
      .max(30, 'Must not exceed 30 chars'),
    payrollId: Yup.string()
      .nullable()
      .required('Must be present')
      .max(50, 'Must not exceed 50 chars'),
  }),
);

const ConsultantPage = (props: BasicPageProps) => {
  function createEmptyConsultant(): Consultant {
    return { id: -1, firstName: '', lastName: '', payrollId: '' };
  }

  const ParameterizedBasePage = BasePage<Consultant>;
  const consultantPage = (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyConsultant}
      queriesImpl={consultantQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Consultant?'
      confirmRemovalDialogText='Are you sure you want to delete current Consultant?'
      confirmSaveDialogText='Are you sure you want to save changes to current Consultant?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Consultant?'
      formatEditEntityPageUrl={(id) => `/consultants/edit/${id}`}
    >
      {(sectionParams) => (
        <Card sx={{ p: 2, pt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='First Name'
                size='small'
                autoFocus={true}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.firstName}
                onChange={sectionParams.updateInputField('firstName')}
                errorText={sectionParams.validationErrors.firstName?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Last Name'
                size='small'
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.lastName}
                onChange={sectionParams.updateInputField('lastName')}
                errorText={sectionParams.validationErrors.lastName?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Payroll ID'
                size='small'
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.payrollId}
                onChange={sectionParams.updateInputField('payrollId')}
                errorText={sectionParams.validationErrors.payrollId?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputWithLabel
                label='Notes'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.notes}
                onChange={sectionParams.updateInputField('notes')}
                errorText={sectionParams.validationErrors.notes?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </ParameterizedBasePage>
  );

  return (
    <AccessControlContextProvider resource='CONSULTANTS'>
      {consultantPage}
    </AccessControlContextProvider>
  );
};

export default ConsultantPage;
