/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo, useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import isEqual from 'lodash/isEqual';
import { ValidationErrors } from 'util/ValidationHelper';
import Consultant, {
  getConsultantFullNameWithPayroll,
} from 'models/Consultant';
import Timesheet from 'models/Timesheet/Timesheet';
import consultantQueries from 'queries/ConsultantQueries';
import periodQueries from 'queries/PeriodQueries';
import DateWithLabel from 'components/input/DateWithLabel';
import InputWithLabel from 'components/input/InputWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type TimesheetInfoCardProps = {
  timesheet: Timesheet;
  availability: any;
  tabIndex: number;
  errors: ValidationErrors<Timesheet>;
  validateWED: (timesheet: Timesheet, date?: Date) => void;
  updateField: (fieldName: keyof Timesheet) => (value: any) => void;
  updateInputField: (
    fieldName: keyof Timesheet,
  ) => (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: any,
  ) => void;
  readOnly: boolean;
};

function isTimesheetInfoEqual(a: Timesheet, b: Timesheet) {
  return (
    isEqual(a.weekEndingDate, b.weekEndingDate) &&
    isEqual(a.period?.id, b.period?.id) &&
    a.isBillFixed === b.isBillFixed &&
    a.fixedBill === b.fixedBill &&
    a.straightTime === b.straightTime &&
    a.overtime === b.overtime &&
    a.holidayTime === b.holidayTime &&
    a.vacationTime === b.vacationTime &&
    a.sickTime === b.sickTime &&
    a.otBillRate === b.otBillRate &&
    a.otPayRate === b.otPayRate &&
    a.perDiem === b.perDiem &&
    a.permFee === b.permFee &&
    a.vendorManagement === b.vendorManagement
  );
}

const TimesheetInfoCard = ({
  availability,
  timesheet,
  tabIndex,
  errors,
  validateWED,
  updateInputField,
  updateField,
  readOnly,
}: TimesheetInfoCardProps) => {
  const [currentConsultantId, setCurrentConsultantId] = useState(-1);

  const { data: matchingPeriods } = periodQueries.get(
    -1,
    0,
    [],
    { consultantIds: [currentConsultantId] },
    '',
    { enabled: currentConsultantId !== -1 },
  );

  const updatePeriod = updateField('period');

  useEffect(() => {
    if (matchingPeriods?.data) updatePeriod(matchingPeriods.data[0]);
  }, [matchingPeriods, updatePeriod]);

  const consultantNameGetter = useCallback(
    (c: Consultant) => getConsultantFullNameWithPayroll(c) || '',
    [],
  );

  const onConsultantChange = useCallback(
    (c: Consultant | null) => c && setCurrentConsultantId(c.id!),
    [setCurrentConsultantId],
  );

  const updateWED = updateField('weekEndingDate');

  const onDateChangeHandler = useCallback(
    (date: Date | undefined) => {
      validateWED(timesheet, date);
      updateWED(date);
    },
    [timesheet.weekEndingDate, updatePeriod, validateWED],
  );

  const isBillFixed = !!(availability.isBillFixed && timesheet.isBillFixed);

  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Timesheet information
      </SoftTypography>
      <Grid2 container columns={15} spacing={1}>
        <Grid2 container size={{ xs: 15 }} columns={15} sx={{ p: 0.5 }}>
          <Grid2 size={{ xs: 4, lg: 3 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateWithLabel
                label='WED'
                autoFocus={true}
                tabIndex={tabIndex}
                value={timesheet.weekEndingDate}
                onChange={onDateChangeHandler}
                error={errors.weekEndingDate?.join(';')}
                readOnly={readOnly}
              />
            </LocalizationProvider>
          </Grid2>
          <Grid2 size={{ xs: 4, lg: 6 }}>
            <SelectWithLabel
              label='Consultant'
              tabIndex={tabIndex}
              optionsFetcher={consultantQueries}
              optionLabelGetter={consultantNameGetter}
              selectedValue={timesheet.period?.consultant}
              onChange={onConsultantChange}
              error={errors.period?.join(';')}
              readOnly={readOnly}
            />
          </Grid2>
          <Grid2 size={{ xs: 4, lg: 3 }}>
            <InputWithLabel
              label='Fixed Bill'
              type='number'
              tabIndex={isBillFixed ? tabIndex : -1}
              value={isBillFixed ? timesheet.fixedBill : null}
              defaultValue={0}
              disabled={!isBillFixed}
              readOnly={!isBillFixed || readOnly}
              onChange={updateInputField('fixedBill')}
              errorText={errors.fixedBill?.join(';')}
            />
          </Grid2>
          <Grid2 size={{ xs: 4, lg: 3 }}>
            <SwitchWithLabel
              label='Is Bill Fixed?'
              tabIndex={availability.isBillFixed ? tabIndex : -1}
              checked={isBillFixed}
              disabled={!availability.isBillFixed}
              readOnly={!availability.isBillFixed || readOnly}
              onChange={updateInputField('isBillFixed')}
            />
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Straight Time'
            type='number'
            tabIndex={availability.straightTime ? tabIndex : -1}
            value={timesheet.straightTime}
            defaultValue={0}
            disabled={!availability.straightTime}
            readOnly={!availability.straightTime || readOnly}
            onChange={updateInputField('straightTime')}
            errorText={errors.straightTime?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Overtime'
            type='number'
            tabIndex={availability.overtime ? tabIndex : -1}
            value={timesheet.overtime}
            defaultValue={0}
            disabled={!availability.overtime}
            readOnly={!availability.overtime || readOnly}
            onChange={updateInputField('overtime')}
            errorText={errors.overtime?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Holiday Time'
            type='number'
            tabIndex={availability.holidayTime ? tabIndex : -1}
            value={timesheet.holidayTime}
            defaultValue={0}
            disabled={!availability.holidayTime}
            readOnly={!availability.holidayTime || readOnly}
            onChange={updateInputField('holidayTime')}
            errorText={errors.holidayTime?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Vacation Time'
            type='number'
            tabIndex={availability.vacationTime ? tabIndex : -1}
            value={timesheet.vacationTime}
            defaultValue={0}
            disabled={!availability.vacationTime}
            readOnly={!availability.vacationTime || readOnly}
            onChange={updateInputField('vacationTime')}
            errorText={errors.vacationTime?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Sick Time'
            type='number'
            tabIndex={availability.sickTime ? tabIndex : -1}
            value={timesheet.sickTime}
            defaultValue={0}
            disabled={!availability.sickTime}
            readOnly={!availability.sickTime || readOnly}
            onChange={updateInputField('sickTime')}
            errorText={errors.sickTime?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='OT Bill Rate'
            type='number'
            tabIndex={availability.otBillRate ? tabIndex : -1}
            value={timesheet.otBillRate}
            defaultValue={0}
            disabled={!availability.otBillRate}
            readOnly={!availability.otBillRate || readOnly}
            onChange={updateInputField('otBillRate')}
            errorText={errors.otBillRate?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='OT Pay Rate'
            type='number'
            tabIndex={availability.otPayRate ? tabIndex : -1}
            value={timesheet.otPayRate}
            defaultValue={0}
            disabled={!availability.otPayRate}
            readOnly={!availability.otPayRate || readOnly}
            onChange={updateInputField('otPayRate')}
            errorText={errors.otPayRate?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Per Diem'
            type='number'
            tabIndex={availability.perDiem ? tabIndex : -1}
            value={timesheet.perDiem}
            defaultValue={0}
            disabled={!availability.perDiem}
            readOnly={!availability.perDiem || readOnly}
            onChange={updateInputField('perDiem')}
            errorText={errors.perDiem?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Perm Fee'
            type='number'
            tabIndex={availability.permFee ? tabIndex : -1}
            value={timesheet.permFee}
            defaultValue={0}
            disabled={!availability.permFee}
            readOnly={!availability.permFee || readOnly}
            onChange={updateInputField('permFee')}
            errorText={errors.permFee?.join(';')}
          />
        </Grid2>
        <Grid2 size={{ xs: 5, lg: 3 }}>
          <InputWithLabel
            label='Vendor Management, %'
            type='number'
            tabIndex={availability.vendorManagement ? tabIndex : -1}
            value={timesheet.vendorManagement}
            defaultValue={0}
            disabled={!availability.vendorManagement}
            readOnly={!availability.vendorManagement || readOnly}
            onChange={updateInputField('vendorManagement')}
            errorText={errors.vendorManagement?.join(';')}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(
  TimesheetInfoCard,
  (before, after) =>
    isTimesheetInfoEqual(before.timesheet, after.timesheet) &&
    isEqual(before.availability, after.availability) &&
    isEqual(before.errors, after.errors) &&
    before.tabIndex === after.tabIndex,
) as typeof TimesheetInfoCard;
