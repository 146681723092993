/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import DateWithLabel from 'components/input/DateWithLabel';
import SoftButton from 'softUI/components/SoftButton';
import IFilterContext from '../context/IFilterContext';
import { useFiltersList } from './filtersList';

type AdvancedFilterProps = {
  passResetFunction?: any;
  filterContext: () => IFilterContext;
};

const AdvancedFilter = ({
  passResetFunction,
  filterContext: filterContextSupplier,
}: AdvancedFilterProps) => {
  const filterContext = filterContextSupplier();

  const resetHandler = () => {
    filterContext.resetAdvancedFilter();
  };
  passResetFunction?.(resetHandler);

  const filtersList = useMemo(
    () => useFiltersList(filterContext),
    [filterContext],
  );

  const dates = useMemo(
    () => [
      {
        label: 'Start Date after',
        value: filterContext.startDate,
        onChange: (date: any) => {
          filterContext.setStartDate(date);
        },
        isReadOnly: filterContext.isStartDateReadOnly,
      },
      {
        label: 'End Date before',
        value: filterContext.endDate,
        onChange: (date: any) => {
          filterContext.setEndDate(date);
        },
        isReadOnly: filterContext.isEndDateReadOnly,
      },
    ],
    [filterContext],
  );

  return (
    <Grid container>
      <Grid container item spacing={2} xs={12}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {dates.map((dateFilter, index) => {
            return (
              <Grid item xs={6} md={4} xl={3} key={index}>
                <DateWithLabel
                  label={dateFilter.label}
                  value={dateFilter.value()}
                  onChange={dateFilter.onChange}
                  disabled={dateFilter.isReadOnly?.()}
                />
              </Grid>
            );
          })}
        </LocalizationProvider>
        {filtersList.map((filter, index) => {
          return 'breakLine' in filter ? (
            <Box width='100%' key={index} />
          ) : (
            <Grid item xs={6} md={4} xl={3} key={index}>
              <AutocompleteWithLabel
                key={index}
                label={filter.label}
                selectAll={filter.hasSelectAll}
                optionsFetcher={filter.optionQueries}
                optionLabelGetter={filter.optionLabelGetter}
                onChange={useCallback(
                  (values: any, ids: any) => {
                    filter.setItems?.(values.length ? values : undefined);
                    filter.setIDs?.(ids.length ? ids : undefined);
                  },
                  [filter],
                )}
                selectedValues={
                  filter.items
                    ? filter.items()
                    : filter.ids?.()?.map((ids) => ({ id: ids }))
                }
                disabled={filter.isReadOnly?.()}
                freeValues={!filter.optionQueries}
                inputType={filter.inputType}
              />
            </Grid>
          );
        })}
      </Grid>
      <SoftButton
        variant='gradient'
        color='info'
        size='small'
        sx={{ mt: 3 }}
        onClick={resetHandler}
      >
        Reset
      </SoftButton>
    </Grid>
  );
};

export default AdvancedFilter;
