/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import UnrestrictedAccessContextProvider from 'security/context/AccessControl/UnrestrictedAccessContextProvider';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import UserRole from 'models/UserRole';
import userRoleQueries from 'queries/UserRoleQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';
import RolePermissions from './RolePermissions';

const validationHelper = new ValidationHelper<UserRole>(
  Yup.object({
    name: Yup.string()
      .required('Must be present')
      .max(50, 'Must not exceed 50 chars'),
    isAdministrator: Yup.boolean().required('Must be present'),
  }),
);

const UserRolePage = (props: BasicPageProps) => {
  function createEmptyUserRole(): UserRole {
    return { id: -1, name: '', isAdministrator: false, permissions: [] };
  }

  const ParameterizedBasePage = BasePage<UserRole>;
  const userRolePage = (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyUserRole}
      queriesImpl={userRoleQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current User Role?'
      confirmRemovalDialogText='Are you sure you want to delete current User Role?'
      confirmSaveDialogText='Are you sure you want to save changes to current User Role?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current User Role?'
      formatEditEntityPageUrl={(id) => `/user-roles/edit/${id}`}
    >
      {(sectionParams) => {
        const role = sectionParams.editedData;
        const tabIndex = sectionParams.tabIndexForInputs;
        const updateInputField = sectionParams.updateInputField;
        const errors = sectionParams.validationErrors;
        const updatePermissionsField = sectionParams.updateField('permissions');
        return (
          <>
            <Card sx={{ mb: 2, p: 2, pt: 1 }}>
              <Grid2 container spacing={3} justifyContent='space-between'>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <InputWithLabel
                    label='Name'
                    size='small'
                    autoFocus={true}
                    tabIndex={tabIndex}
                    value={role.name}
                    onChange={updateInputField('name')}
                    errorText={errors.name?.join(';')}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <SwitchWithLabel
                    label='Administrator'
                    tabIndex={tabIndex}
                    checked={role.isAdministrator}
                    onChange={updateInputField('isAdministrator')}
                    error={errors.isAdministrator?.join(';')}
                  />
                </Grid2>
              </Grid2>
            </Card>
            {!role.isAdministrator && (
              <Card sx={{ mt: 2, p: 2 }}>
                <RolePermissions
                  permissions={role.permissions}
                  tabIndex={tabIndex}
                  onChange={updatePermissionsField}
                />
              </Card>
            )}
          </>
        );
      }}
    </ParameterizedBasePage>
  );

  return (
    <UnrestrictedAccessContextProvider>
      {userRolePage}
    </UnrestrictedAccessContextProvider>
  );
};

export default UserRolePage;
