/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import AccessControlContext from './AccessControlContext';

const ReadOnlyContextProvider = ({ children }: any) => {
  return (
    <AccessControlContext.Provider
      value={{
        canCreate: () => false,
        canUpdate: () => false,
        canDelete: () => false,
        canImport: false,
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
};
export default ReadOnlyContextProvider;
