/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import {
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { asUTC } from 'util/Formatters';
import BaseReport from 'models/reports/BaseReport';
import ReportDetails from 'models/reports/ReportDetails';
import { Sort } from './BaseFetchingQueries';
import { BaseWrappedListingQueries } from './BaseWrappedListingQueries';
import { DataWrapper } from './BaseWrappedListingQueries';
import http from './HttpClient';

class ReportQueries implements BaseWrappedListingQueries<ReportDetails> {
  get(
    pageSize = -1,
    page = 0,
    sort: Sort[] = [],
    filter: any = {},
    searchTerms = '',
    options?: any,
  ): UseQueryResult<DataWrapper<ReportDetails[]>> {
    return useQuery({
      queryKey: [
        'reports',
        {
          pageSize: pageSize,
          page: page,
          sort: sort,
          filter: filter,
          searchTerms: searchTerms,
        },
      ],
      queryFn: async () => {
        const response = await http.get<ReportDetails[]>('/reports', {
          params: {
            start: pageSize * page,
            limit: pageSize,
            sort: JSON.stringify(sort),
            filter: JSON.stringify(filter),
            query: searchTerms,
          },
        });

        return {
          data: response.data,
          success: true,
          totalCount: response.data.length,
        };
      },

      staleTime: 5 * 1000,
      placeholderData: keepPreviousData,
      ...options,
    });
  }

  async generate(report: BaseReport): Promise<ReportDetails> {
    const adaptedRequest = {
      ...report,
      startDate: report.startDate ? asUTC(report.startDate) : null,
      endDate: report.endDate ? asUTC(report.endDate) : null,
    };
    const response = await http.post(`/reports`, adaptedRequest);
    return response.data;
  }

  async getDetails(reportId: number): Promise<ReportDetails> {
    const response = await http.get(`/reports/${reportId}`);
    return response.data;
  }

  async getData(reportId: number): Promise<Blob> {
    const response = await http.get(`/reports/${reportId}/data`, {
      timeout: 60000,
      responseType: 'blob',
    });
    return response.data;
  }
}

export default new ReportQueries();
