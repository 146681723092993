/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { useAuth } from 'security/context/Authentication/AuthContext';
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from 'components/navigation/DashboardNavbar/styles';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';
import { setMiniSidenav, useSoftUIController } from 'softUI/context';

function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
  const [navbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, currentPageTitle } = controller;
  const { profile, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [openProfileMenu, setOpenProfileMenu] = useState(null);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleChangePasswordClick = () => {
    navigate('/change-password', {
      state: { referer: location.pathname },
    });
  };
  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };
  const handleOpenProfileMenu = ({ currentTarget }) =>
    setOpenProfileMenu(currentTarget);
  const handleCloseProfileMenu = () => setOpenProfileMenu(null);
  const renderProfileMenu = () => (
    <Menu
      anchorEl={openProfileMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(openProfileMenu)}
      onClose={handleCloseProfileMenu}
    >
      <SoftBox component='div' width='100%' align='center'>
        <SoftTypography variant='caption' color='secondary'>
          Logged in as
        </SoftTypography>
        <SoftTypography variant='inherit'>{profile.role.name}</SoftTypography>
      </SoftBox>
      <SoftBox
        component='div'
        bgColor='secondary'
        opacity={0.3}
        width='100%'
        height='1px'
        my={1}
      />
      <MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color='inherit'
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          color='inherit'
          mb={{ xs: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <SoftTypography
            variant='h6'
            fontWeight='bold'
            color={light ? 'white' : 'dark'}
          >
            {currentPageTitle || ''}
          </SoftTypography>
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <SoftBox color={light ? 'white' : 'inherit'}>
              <IconButton
                size='small'
                color='inherit'
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {!miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {renderProfileMenu()}
              <IconButton
                size='small'
                color='inherit'
                sx={navbarIconButton}
                onClick={handleOpenProfileMenu}
              >
                <Icon>account_circle</Icon>
              </IconButton>
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
