/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useState } from 'react';
import React from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import isEqual from 'lodash/isEqual';
import UnrestrictedAccessContextProvider from 'security/context/AccessControl/UnrestrictedAccessContextProvider';
import SystemConfig from 'models/SystemConfig';
import { getErrorMessage } from 'queries/ErrorResponse';
import configQueries from 'queries/SystemConfigQueries';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ResponsiveButton from 'components/ResponsiveButton';
import SoftBox from 'softUI/components/SoftBox';
import SoftInput from 'softUI/components/SoftInput';
import SoftTypography from 'softUI/components/SoftTypography';
import { useSoftUIController } from 'softUI/context';
import { setCurrentPageTitle } from 'softUI/context';

const SystemConfigPage = (props: any) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);

  const getQuery = configQueries.get();
  const navigate = useNavigate();
  useEffect(() => {
    if (getQuery.isPending) {
      getQuery.refetch();
    } else if (getQuery.isSuccess) {
      setInitialData(getQuery.data);
      setEditedData(getQuery.data);
    } else if (getQuery.isError) {
      navigate('/');
    }
  }, [getQuery.status]);

  const [initialData, setInitialData] = useState([] as SystemConfig[]);
  const [editedData, setEditedData] = useState([] as SystemConfig[]);

  function isEditedDataInDirtyState() {
    return !isEqual(initialData, editedData);
  }
  const editedDataIsInDirtyState = isEditedDataInDirtyState();
  const navigationBlocker = useBlocker(editedDataIsInDirtyState);
  useEffect(() => {
    function beforeunloadListener(e: any) {
      if (editedDataIsInDirtyState) {
        e.preventDefault();
        e.returnValue = 'onbeforeunload';
        return '';
      }
    }
    window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, [editedDataIsInDirtyState]);

  function handleChangeConfig(id: string, newValue: any) {
    setEditedData(
      editedData.map((e) => {
        if (e.id !== id) return e;
        const updated = { ...e };
        if (newValue && newValue.length > 0) {
          updated.value = newValue;
        } else {
          delete updated.value;
        }
        return updated;
      }),
    );
  }

  const [confirmResetDialogIsOpen, setConfirmResetDialogIsOpen] =
    useState(false);
  function handleResetBtnClick() {
    setConfirmResetDialogIsOpen(true);
  }
  function handleConfirmResetDialogResult(isConfirmed: boolean) {
    setConfirmResetDialogIsOpen(false);
    if (isConfirmed) {
      setEditedData(initialData);
    }
  }

  const [confirmSaveDialogIsOpen, setConfirmSaveDialogIsOpen] = useState(false);
  function handleSaveBtnClick() {
    setConfirmSaveDialogIsOpen(true);
  }

  const [progressBackdropIsOpen, setProgressBackdropIsOpen] = useState(false);
  const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

  const mutationQuery = configQueries.update();
  function handleConfirmSaveDialogResult(isConfirmed: boolean) {
    setConfirmSaveDialogIsOpen(false);
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          const response = await mutationQuery.mutateAsync(editedData);
          const responseData = response.data;
          if (responseData?.success && responseData?.data) {
            setInitialData(responseData.data);
            setEditedData(responseData.data);
          } else {
            setInitialData(editedData);
          }
        } catch (error: any) {
          console.log(error);
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Saving failed'),
          );
        } finally {
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }

  return (
    <UnrestrictedAccessContextProvider>
      <SoftBox pb={1.5} display='flex' justifyContent='end'>
        <ResponsiveButton
          label='Save'
          variant='contained'
          icon='save'
          iconBaseClass='material-icons-outlined'
          color='info'
          onClick={() => handleSaveBtnClick()}
          tabIndex={2}
          disabled={!editedDataIsInDirtyState}
        />
        <ResponsiveButton
          label='Reset'
          variant='outlined'
          icon='restart_alt'
          color='info'
          onClick={handleResetBtnClick}
          tabIndex={2}
          disabled={!editedDataIsInDirtyState}
        />
      </SoftBox>
      <Card sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {editedData?.map((config) => (
            <React.Fragment key={config.id}>
              <Grid item xs={12} md={7} lg={8}>
                <SoftTypography variant='button' fontWeight='medium'>
                  {config.id}
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <SoftInput
                  size='small'
                  variant='outlined'
                  value={config.value ?? ''}
                  placeholder={
                    config.defaultValue ? `Default: ${config.defaultValue}` : ''
                  }
                  type={config.type === 'Number' ? 'number' : 'text'}
                  onChange={(e: any) =>
                    handleChangeConfig(config.id, e.target.value)
                  }
                  slotProps={{
                    input: {
                      tabIndex: 1,
                    },
                  }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Card>

      <ConfirmationDialog
        open={confirmResetDialogIsOpen}
        title='Reset'
        description={
          'Are you sure you want to reset changes performed to system\u00A0configuration?'
        }
        resultCallback={handleConfirmResetDialogResult}
      />
      <ConfirmationDialog
        open={confirmSaveDialogIsOpen}
        title='Save'
        description={
          'Are you sure you want to save changes to system\u00A0configuration?'
        }
        resultCallback={handleConfirmSaveDialogResult}
      />
      <ConfirmationDialog
        open={navigationBlocker.state === 'blocked'}
        title='Unsaved changes'
        description={
          'You have changes to system\u00A0configuration. Do you want to discard changes and leave the page?'
        }
        resultCallback={(isConfirmed) => {
          setTimeout(
            () =>
              isConfirmed
                ? navigationBlocker.proceed!()
                : navigationBlocker.reset!(),
            0,
          );
        }}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progressBackdropIsOpen}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar
        open={!!notificationErrorMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationErrorMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          <pre style={{ fontFamily: 'inherit' }}>
            {notificationErrorMessage}
          </pre>
        </Alert>
      </Snackbar>
    </UnrestrictedAccessContextProvider>
  );
};

export default SystemConfigPage;
