/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftButton from 'softUI/components/SoftButton';

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  description: string;
  resultCallback: (isConfirmed: boolean) => void;
  yesButtonCaption?: string;
  noButtonCaption?: string;
  disableNoButton?: boolean;
};

const ConfirmationDialog = ({
  yesButtonCaption = 'Yes',
  noButtonCaption = 'No',
  ...props
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.resultCallback(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      disableRestoreFocus
    >
      <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SoftButton
          variant='contained'
          color='info'
          size='small'
          onClick={() => props.resultCallback(true)}
          autoFocus={true}
        >
          {yesButtonCaption}
        </SoftButton>
        {!props.disableNoButton && (
          <SoftButton
            variant='contained'
            color='info'
            size='small'
            onClick={() => props.resultCallback(false)}
          >
            {noButtonCaption}
          </SoftButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
