/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { TableCell } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import TableRow from '@mui/material/TableRow';
import borders from 'softUI/assets/theme/base/borders';
import colors from 'softUI/assets/theme/base/colors';
import SoftTypography from 'softUI/components/SoftTypography';
import PermissionConditions from './PermissionConditions';
import PermissionDateConditions from './PermissionDateConditions';
import PermissionTableModel from './PermissionTableModel';
import { PermissionActionDef, PermissionDef } from './appPermissions';

const { borderWidth } = borders;
const { light } = colors;

type TableRowsWithPermissionsProps = {
  tableModel: PermissionTableModel;
  onAddCondition: (permission: PermissionDef) => void;
  onChangeCondition: (
    permission: PermissionDef,
    conditions: PermissionConditions,
    value: PermissionConditions,
  ) => void;
  onChangeAction: (
    permission: PermissionDef,
    conditions: PermissionConditions | undefined,
    action: PermissionActionDef,
    value: boolean,
  ) => void;
  tabIndex: number;
};

export default function TableRowsWithPermissions({
  tableModel,
  onAddCondition,
  onChangeCondition,
  onChangeAction,
  tabIndex,
}: TableRowsWithPermissionsProps) {
  return (
    <>
      {tableModel.permissions.map((permission) => {
        const permissionDef = permission.permissionDef;
        const rowKey = permission.rowKey;
        return (
          <TableRow key={rowKey}>
            <TableCell
              align='left'
              sx={{
                pl: 1,
                py: 0.25,
                borderBottom: 0,
                borderTop: `${borderWidth[1]} solid ${light.main}`,
              }}
            >
              {!permission.conditions ? (
                <>
                  <SoftTypography variant='button' fontWeight='regular'>
                    {permissionDef.name}
                  </SoftTypography>
                  {permissionDef.supportConditions && (
                    <IconButton
                      size='small'
                      edge='end'
                      aria-label='add'
                      color='inherit'
                      tabIndex={tabIndex}
                      onClick={() => onAddCondition(permissionDef)}
                    >
                      <Icon title='Add condition' fontSize='small'>
                        add
                      </Icon>
                    </IconButton>
                  )}
                </>
              ) : (
                <PermissionDateConditions
                  value={permission.conditions}
                  tabIndex={tabIndex}
                  onChange={(value) =>
                    onChangeCondition(
                      permissionDef,
                      permission.conditions!,
                      value,
                    )
                  }
                />
              )}
            </TableCell>
            {tableModel.actions.map((action) => (
              <TableCell
                key={`${rowKey}-${action.action}`}
                align='center'
                sx={{
                  py: 0.25,
                  borderBottom: 0,
                  borderTop: `${borderWidth[1]} solid ${light.main}`,
                }}
              >
                {permission.permissionDef.supportedActions.has(
                  action.action,
                ) && (
                  <Switch
                    size='small'
                    checked={permission.actions.has(action.action)}
                    tabIndex={tabIndex}
                    onChange={(_, checked) =>
                      onChangeAction(
                        permissionDef,
                        permission.conditions,
                        action,
                        checked,
                      )
                    }
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
}
