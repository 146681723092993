/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { asUTC } from 'util/Formatters';
import Period from 'models/Period/Period';
import getAvailability from 'models/Period/getAvailability';
import BaseModelQueries from './BaseModelQueries';

class PeriodQueries extends BaseModelQueries<Period> {
  constructor(url: string) {
    super(url);
  }

  toRequest(period: Period): Period {
    const availability = getAvailability(period);

    const request = {
      ...period,
      startDate: asUTC(new Date(period.startDate)),
      endDate: asUTC(new Date(period.endDate)),

      billRatePermFee: period.isBillFixed ? 0 : period.billRatePermFee,
      perDiem:
        !availability.isDirectlyHired && availability.perDiem
          ? period.perDiem
          : 0,
      otBillRate: availability.otBillRate ? period.otBillRate || 0 : undefined,
      otPayRate: availability.otPayRate ? period.otPayRate || 0 : undefined,
      spreadOver: availability.spreadOver ? period.spreadOver || 0 : undefined,
      fixedBill:
        !availability.isDirectlyHired &&
        availability.isBillFixed &&
        period.isBillFixed
          ? period.fixedBill || 0
          : undefined,
      isBillFixed:
        !availability.isDirectlyHired && availability.isBillFixed
          ? !!period.isBillFixed
          : undefined,

      guaranteePeriod: availability.isDirectlyHired
        ? period.guaranteePeriod
        : undefined,
      guaranteeEndDate:
        availability.isDirectlyHired && period.guaranteeEndDate
          ? asUTC(new Date(period.guaranteeEndDate))
          : undefined,

      vendorName: availability.vendorName ? period.vendorName : undefined,
    };
    return request as Period;
  }
}

export default new PeriodQueries('/periods');
