/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import Filter from 'models/Filter';
import http from './HttpClient';

export enum ExportImportType {
  Clients = 'clients',
  Consultants = 'consultants',
  Employees = 'employees',
  EmploymentTypes = 'employmenttypes',
  IndustryTypes = 'industrytypes',
  InvoicingFrequencies = 'invoicingfrequencies',
  Locations = 'locations',
  PayPeriods = 'payperiods',
  Pools = 'pools',
  Positions = 'positions',
  Periods = 'periods',
  Timesheets = 'timesheets',
}

export type ExportImportProgress = {
  id: string;
  progressPercent: number;
  completed: boolean;
  hasResults: boolean;
  failureDetails?: string;
};

class ExportImportQueries {
  async initiateExport(
    type: ExportImportType,
    filter: Filter,
  ): Promise<ExportImportProgress> {
    const response = await http.post(`/export/${type}`, filter);
    return response.data;
  }

  async getExportProgress(
    type: ExportImportType,
    id: string,
  ): Promise<ExportImportProgress> {
    const response = await http.get(`/export/${type}/${id}`);
    return response.data;
  }

  async getExportResult(type: ExportImportType, id: string): Promise<Blob> {
    const response = await http.get(`/export/${type}/${id}/result`, {
      timeout: 60000,
      responseType: 'blob',
    });
    return response.data;
  }

  async initiateImport(
    type: ExportImportType,
    fileList: File[],
  ): Promise<ExportImportProgress> {
    const formData = new FormData();
    for (const file of fileList) {
      formData.append('files', file);
    }
    const response = await http.postForm(`/import/${type}`, formData);
    return response.data;
  }

  async getImportProgress(
    type: ExportImportType,
    id: string,
  ): Promise<ExportImportProgress> {
    const response = await http.get(`/import/${type}/${id}`);
    return response.data;
  }

  async getImportResult(type: ExportImportType, id: string): Promise<Blob> {
    const response = await http.get(`/import/${type}/${id}/result`, {
      timeout: 60000,
      responseType: 'blob',
    });
    return response.data;
  }
}

export default new ExportImportQueries();
