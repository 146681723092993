/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import RolePermission from 'models/RolePermission';
import SoftBox from 'softUI/components/SoftBox';
import SoftTypography from 'softUI/components/SoftTypography';
import { convertToModel } from './PermissionTableModel';
import TableHeaderWithActions from './TableHeaderWithActions';
import TableRowsWithPermissions from './TableRowsWithPermissions';
import {
  addPermissionConditions,
  updatePermissionAction,
  updatePermissionConditions,
} from './permissionOperations';

type Props = {
  permissions: RolePermission[];
  onChange?: (permissions: RolePermission[]) => void;
  tabIndex: number;
};

const RolePermissions = ({ permissions, onChange, tabIndex }: Props) => {
  const tableModel = convertToModel(permissions);
  return (
    <>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Permissions
      </SoftTypography>
      <SoftBox sx={{ pt: 1.5 }}>
        <Table>
          <TableHeaderWithActions actions={tableModel.actions} />
          <TableBody>
            <TableRowsWithPermissions
              tableModel={tableModel}
              onAddCondition={(perm) => {
                onChange?.(addPermissionConditions(permissions, perm));
              }}
              onChangeCondition={(perm, conditions, value) => {
                onChange?.(
                  updatePermissionConditions(
                    permissions,
                    perm,
                    conditions,
                    value,
                  ),
                );
              }}
              onChangeAction={(perm, conditions, act, checked) =>
                onChange?.(
                  updatePermissionAction(
                    permissions,
                    perm,
                    conditions,
                    act,
                    checked,
                  ),
                )
              }
              tabIndex={tabIndex}
            />
          </TableBody>
        </Table>
      </SoftBox>
    </>
  );
};

export default RolePermissions;
