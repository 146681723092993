/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { ReactNode } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from 'security/context/Authentication/AuthContext';
import DashboardLayout from 'layouts/DashboardLayout';
import AuthenticationPage from 'pages/AuthenticationPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import SignInPage from 'pages/SignInPage';
import RouteType, { RouteAcl } from './RouteType';
import appRoutes from './appRoutes';
import matchAcl from './matchAcl';

export default function RootRoutes() {
  const { isReady, profile, permissions } = useAuth();

  function generateRoutes(
    routes: RouteType[],
    parentAcl?: RouteAcl,
  ): ReactNode {
    return routes.map((route) => {
      const result: ReactNode[] = [];
      const necessaryAcl = parentAcl
        ? { ...parentAcl, ...route.acl }
        : route.acl;
      if (!necessaryAcl || matchAcl(profile!.role, permissions, necessaryAcl)) {
        if (route.route) {
          result.push(
            <Route
              path={route.route}
              element={route.component}
              key={route.key}
            />,
          );
        }
        if (route.child) {
          const generatedChildRoutes = generateRoutes(
            route.child,
            necessaryAcl,
          );
          if (!isEmpty(generatedChildRoutes)) {
            result.push(generatedChildRoutes);
          }
        }
      }
      return result;
    });
  }

  const routeElements = (
    <>
      {profile && (
        <Route path='/' element={<DashboardLayout />}>
          {generateRoutes(appRoutes)}
        </Route>
      )}
      {!profile && isReady && <Route path='/login' element={<SignInPage />} />}
      <Route path='/change-password' element={<ChangePasswordPage />} />
      <Route
        path='*'
        element={
          profile ? (
            <Navigate to='/dashboard' />
          ) : isReady ? (
            <NavigateWithReferer to='/login' />
          ) : (
            <AuthenticationPage />
          )
        }
      />
    </>
  );

  const browserRouter = createBrowserRouter(
    createRoutesFromElements(routeElements),
  );

  return <RouterProvider router={browserRouter} />;
}

const NavigateWithReferer = ({ to }: any) => {
  const location = useLocation();
  return <Navigate to={to} state={{ referer: location.pathname }} />;
};
