/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  HTMLAttributes,
  HTMLInputTypeAttribute,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SxProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import isEqual from 'lodash/isEqual';
import { BaseFetchingQueries } from 'queries/BaseFetchingQueries';
import BaseLabeledComponent, { prefixWithLabel } from './BaseLabeledComponent';
import OptionsFetcher from './OptionsFetcher';

type SelectWithLabelProps<OptionValue, OptionKey> = {
  additionalOptions?: OptionValue[];
  autoFocus?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  error?: string;
  inputType?: HTMLInputTypeAttribute;
  isOptionEqualTo?: (option1: OptionValue, option2: OptionValue) => boolean;
  label?: string;
  onChange: (selectedValue: OptionValue | null) => void;
  optionFilter?: (option: OptionValue) => boolean;
  optionIdGetter?: (option: OptionValue) => OptionKey;
  optionLabelGetter?: (option: OptionValue) => string | undefined;
  optionsFetcher?:
    | OptionsFetcher<OptionValue>
    | BaseFetchingQueries<OptionValue>;
  passResetFunction?: (resetFunction: () => void) => void;
  readOnly?: boolean;
  selectedValue?: OptionValue | null;
  sx?: SxProps<Theme>;
  tabIndex?: number;
};

const SelectWithLabel = <OptionValue, OptionKey = number | string>({
  disableClearable = false,
  inputType = 'text',
  // @ts-ignore
  isOptionEqualTo = (option1, option2) => option1?.id === option2?.id,
  // @ts-ignore
  optionIdGetter = (option) => option.id,
  // @ts-ignore
  optionLabelGetter = (option) => option.name,
  ...props
}: SelectWithLabelProps<OptionValue, OptionKey>) => {
  // Options - the source.
  const { isFetching, isSuccess, data, refetch } =
    (props.optionsFetcher &&
      ('getAll' in props.optionsFetcher
        ? props.optionsFetcher.getAll()
        : props.optionsFetcher())) ||
    {};

  // Options - filter & extract the labels.
  const [options, optionLabels] = useMemo(() => {
    if (isSuccess && data) {
      const completed = props.additionalOptions
        ? props.additionalOptions.concat(data)
        : data;
      const filtered = props.optionFilter
        ? completed.filter(props.optionFilter)
        : completed;
      const labels = new Map<any, string>(
        filtered.map((option) => [
          optionIdGetter(option),
          optionLabelGetter(option) || '',
        ]),
      );
      return [filtered, labels];
    } else {
      return [[], new Map()];
    }
  }, [
    isSuccess,
    data,
    props.additionalOptions,
    props.optionFilter,
    optionIdGetter,
    optionLabelGetter,
  ]);

  // Options - prefetch to be able to show the selected option labels.
  const requiresPrefetch = useMemo(
    () =>
      !optionLabels.size &&
      props.selectedValue &&
      optionLabelGetter(props.selectedValue) === undefined,
    [optionLabels, props.selectedValue, optionLabelGetter],
  );
  const fetchOptions = () => !isSuccess && refetch?.();
  if (requiresPrefetch) fetchOptions();

  // Options - equal function for marking selected options.
  const isOptionEqualToValue = (option1: OptionValue, option2: OptionValue) => {
    return (
      option1 === option2 ||
      (typeof option1 === 'object' &&
        typeof option2 === 'object' &&
        isOptionEqualTo(option1, option2))
    );
  };

  // Selected - the intermediate state while the select box is open. Changes are to be propagated once the box is closed.
  const [selected, setSelected] = useState<OptionValue | null>(null);

  // Selected - propagate changed selectedValues.
  useEffect(() => {
    setSelected(props.selectedValue || null);
  }, [props.selectedValue]);

  // Selected - callback to reset the state.
  props.passResetFunction?.(() => setSelected(null));

  // Selected - sieve non-existent options.
  useEffect(() => {
    if (selected && optionLabels.size) {
      if (!optionLabels.has(optionIdGetter(selected))) {
        setSelected(null);
        callOnChange(null);
      }
    }
  }, [optionLabels]);

  // Selected - on change event handler.
  const onChangeHandler = (_: any, value: OptionValue | null) => {
    setSelected(value);
    callOnChange(value);
  };

  // Selected - propagate changes.
  const callOnChange = (value: OptionValue | null) => {
    props.onChange(value);
  };

  // Option render - the label resolver.
  const getOptionLabel = (option: OptionValue): string => {
    if (optionLabels.size) {
      const result = optionLabels.get(optionIdGetter(option));
      if (result) {
        return result;
      }
    }
    return optionLabelGetter(option) ?? 'Loading...';
  };

  // Option render.
  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: OptionValue,
  ) => {
    return (
      <li
        {...props}
        key={(optionIdGetter(option) as any) || (option as string)}
      >
        {getOptionLabel(option)}
      </li>
    );
  };

  // Input - the renderer.
  const renderInput = (params: any) => (
    <TextField
      {...params}
      type={inputType}
      placeholder={props.disabled || props.readOnly ? '' : 'Type to search'}
      autoFocus={props.autoFocus}
      inputProps={{
        ...params.inputProps,
        tabIndex: props.tabIndex,
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isFetching ? (
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ position: 'absolute', right: '12px' }}
              />
            ) : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      sx={{
        '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
          display: props.readOnly ? 'none' : '',
        },
      }}
    />
  );
  return (
    <BaseLabeledComponent label={props.label} error={props.error} sx={props.sx}>
      <Autocomplete
        disableClearable={disableClearable}
        disableCloseOnSelect={props.disableCloseOnSelect}
        disabled={props.disabled}
        getOptionLabel={getOptionLabel}
        id={prefixWithLabel(props.label || '', 'autocomplete')}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={isFetching}
        options={options}
        onOpen={fetchOptions}
        onChange={onChangeHandler}
        renderOption={renderOption}
        renderInput={renderInput}
        readOnly={props.readOnly}
        size='small'
        value={selected}
      />
    </BaseLabeledComponent>
  );
};

export default memo(
  SelectWithLabel,
  (before, after) =>
    before.autoFocus === after.autoFocus &&
    before.disableClearable === after.disableClearable &&
    before.disableCloseOnSelect === after.disableCloseOnSelect &&
    before.disabled === after.disabled &&
    before.error === after.error &&
    before.inputType === after.inputType &&
    before.isOptionEqualTo === after.isOptionEqualTo &&
    before.label === after.label &&
    before.onChange === after.onChange &&
    before.optionFilter === after.optionFilter &&
    before.optionIdGetter === after.optionIdGetter &&
    before.optionLabelGetter === after.optionLabelGetter &&
    before.optionsFetcher === after.optionsFetcher &&
    // expecting functions to be wrapped with 'useCallback'
    before.passResetFunction === after.passResetFunction &&
    before.readOnly === after.readOnly &&
    isEqual(before.selectedValue, after.selectedValue) &&
    isEqual(before.sx, after.sx) &&
    before.tabIndex === after.tabIndex,
) as typeof SelectWithLabel;
