/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert/Alert';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnResizeParams,
  GridEventListener,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortItem,
  GridToolbarContainer,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { useAccessControlContext } from 'security/context/AccessControl/AccessControlContext';
import { Sort } from 'queries/BaseFetchingQueries';
import BaseModelQueries from 'queries/BaseModelQueries';
import { BaseWrappedListingQueries } from 'queries/BaseWrappedListingQueries';
import { getErrorMessage } from 'queries/ErrorResponse';
import { ExportImportType } from 'queries/ExportImportQueries';
import IFilterContext from 'components/CombinedFilter/context/IFilterContext';
import ConfirmationDialog from 'components/ConfirmationDialog';
import EntityExporter from 'components/EntityExporter';
import EntityImporter from 'components/EntityImporter';
import ResponsiveButton from 'components/ResponsiveButton';
import SoftBox from 'softUI/components/SoftBox';
import SoftProgress from 'softUI/components/SoftProgress';
import { setCurrentPageTitle, useSoftUIController } from 'softUI/context';

export type BasicPageProperties = {
  title: string;
};

export type PageProperties = BasicPageProperties & {
  gridColumns: GridColDef[];
  defaultSort?: GridSortItem;
  queriesImpl: BaseWrappedListingQueries<any> | BaseModelQueries<any>;
  localStorageItemName?: string;
  useFilterContext?: () => IFilterContext;
  filterComponent?: any;

  showCopyButton?: boolean;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showRemoveButton?: boolean;
  showExportButton?: boolean;
  showImportButton?: boolean;
  checkboxSelection?: boolean;

  gridBoxHeight: string;

  addNewEntityPageUrl?: string;
  formatEditEntityPageUrl?: (
    id: number,
    setProgressBackdropIsOpen: (isOpen: boolean) => void,
    setNotificationErrorMessage: (message: string) => void,
  ) => string;

  editButtonLabel?: string;
  editButtonIcon?: string;
  exportConfirmationDialogText?: string;
  formatRemoveConfirmationDialogText?: (id: number | number[]) => string;
  formatExportConfirmationDialogText?: (ids: number[]) => string;

  exportImportType?: ExportImportType;
};

const BaseListPage = (props: PageProperties) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);

  const getSavedTableState = () => {
    const item =
      props.localStorageItemName &&
      localStorage.getItem(props.localStorageItemName);
    return item ? JSON.parse(item) : {};
  };
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    () => ({
      pageSize: getSavedTableState().pageSize || 25,
      page: 0,
    }),
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    () =>
      getSavedTableState().sortModel ||
      (props.defaultSort
        ? [props.defaultSort]
        : [{ field: 'id', sort: 'desc' }]),
  );
  const [columnModel, setColumnModel] = useState<GridColumnVisibilityModel>(
    () => getSavedTableState().columnModel || {},
  );
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(
    () => getSavedTableState().columnWidths || {},
  );
  const handleColumnResize = (params: GridColumnResizeParams) => {
    setColumnWidths({
      ...columnWidths,
      [params.colDef.field]: params.width | 0,
    });
  };

  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  useEffect(() => {
    if (props.localStorageItemName) {
      localStorage.setItem(
        props.localStorageItemName,
        JSON.stringify({
          pageSize: paginationModel.pageSize,
          sortModel: sortModel,
          columnModel: columnModel,
          columnWidths: columnWidths,
        }),
      );
    }
  }, [paginationModel, sortModel, columnModel, columnWidths]);

  const sortForQuery = useMemo(() => {
    return sortModel.map(
      ({ field, sort }: GridSortItem) =>
        ({
          property: field,
          direction: sort?.toString(),
        }) as Sort,
    );
  }, [sortModel]);

  const searchTermsForQuery = useMemo(() => {
    return filterModel?.quickFilterValues?.join(' ') || '';
  }, [filterModel]);
  const filterContext = props.useFilterContext?.();
  const filterForQuery = useMemo(() => {
    return filterContext ? filterContext.buildFilter() : {};
  }, [filterContext]);

  const getEntitiesQuery = props.queriesImpl.get(
    paginationModel.pageSize,
    paginationModel.page,
    sortForQuery,
    filterForQuery,
    searchTermsForQuery,
  );

  const [progressBackdropIsOpen, setProgressBackdropIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<
    string | React.JSX.Element
  >('');
  const [notificationSeverity, setNotificationSeverity] =
    useState<AlertColor>('info');

  function showNotification(
    severity: AlertColor,
    message: string,
    progressPercentage?: number,
  ) {
    setNotificationSeverity(severity);
    const formattedMessage =
      progressPercentage !== undefined ? (
        <>
          <pre style={{ fontFamily: 'inherit' }}>{message}</pre>
          <SoftProgress value={progressPercentage} label />
        </>
      ) : (
        message
      );
    setNotificationMessage(formattedMessage);
  }

  const setNotificationErrorMessage = (message: string) => {
    setNotificationSeverity('error');
    setNotificationMessage(message);
  };

  const navigate = useNavigate();
  const onRowDoubleClick: GridEventListener<'rowDoubleClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    event.defaultMuiPrevented = true;
    if (props.formatEditEntityPageUrl) {
      const selectedId = params.id as number;
      const editPageUrl = props.formatEditEntityPageUrl(
        selectedId,
        setProgressBackdropIsOpen,
        setNotificationErrorMessage,
      );
      navigate(editPageUrl);
    } else if (props.showEditButton) {
      console.error(
        'list page: showEditButton is set, but formatEditEntityPageUrl is missing - misconfiguration?',
      );
    }
  };

  const apiRef = useGridApiRef();
  useEffect(() => {
    return apiRef.current.subscribeEvent('cellMouseUp', onGridCellMouseUp);
  }, [apiRef]);
  const onGridCellMouseUp: GridEventListener<'cellMouseUp'> = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    // If the middle mouse button is clicked.
    if (event.button == 1) {
      event.defaultMuiPrevented = true;
      if (props.formatEditEntityPageUrl) {
        const selectedId = params.row.id as number;
        const editPageUrl = props.formatEditEntityPageUrl(
          selectedId,
          setProgressBackdropIsOpen,
          setNotificationErrorMessage,
        );
        window.open(editPageUrl, '_blank');
      }
    }
  };

  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([]);
  const onRowSelectionModelChange = (selectionModel: GridRowSelectionModel) => {
    setSelectedEntityIds(selectionModel as number[]);
  };

  function getSelectedEntity() {
    return getEntitiesQuery?.data?.data?.find(
      (entity) => entity.id === selectedEntityIds[0],
    );
  }

  const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] =
    useState(false);

  function onDeleteBtnClick() {
    setConfirmDeleteDialogIsOpen(true);
  }

  const deleteEntityQuery =
    'delete' in props.queriesImpl ? props.queriesImpl.delete(false) : undefined;
  const removeCachedQueries =
    'getRemoveCachedQueriesFunction' in props.queriesImpl
      ? props.queriesImpl.getRemoveCachedQueriesFunction()
      : undefined;

  function onConfirmDeleteDialogResult(isConfirmed: boolean) {
    setConfirmDeleteDialogIsOpen(false);
    if (isConfirmed) {
      setProgressBackdropIsOpen(true);
      setTimeout(async () => {
        try {
          await Promise.all(
            selectedEntityIds.map((id) =>
              deleteEntityQuery ? deleteEntityQuery.mutateAsync(id) : id,
            ),
          );
        } catch (error: any) {
          setNotificationErrorMessage(
            await getErrorMessage(error, 'Removal failed'),
          );
        } finally {
          apiRef.current?.setRowSelectionModel([]);
          removeCachedQueries?.();
          setProgressBackdropIsOpen(false);
        }
      });
    }
  }

  const entitiesToExport = props.checkboxSelection ? selectedEntityIds : [];
  const exportFilter =
    entitiesToExport.length > 0 ? { ids: entitiesToExport } : filterForQuery;

  function handleExportImportProgressBackdropChange(isOpen: boolean) {
    setProgressBackdropIsOpen(isOpen);
    if (!isOpen) {
      removeCachedQueries?.();
    }
  }

  const customGridToolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
          variant='outlined'
          debounceMs={500}
          autoFocus={true}
          InputProps={{
            size: 'small',
            startAdornment: null,
            endAdornment: null,
          }}
          inputProps={{
            tabIndex: 0,
          }}
        />
      </GridToolbarContainer>
    );
  }, []);

  const columnsWithWidths = props.gridColumns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  function GridColumnMenuWithResetWidth(props: GridColumnMenuProps) {
    const { hideMenu, colDef } = props;

    const handleCustomAction = () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [colDef.field]: field, ...newWidths } = columnWidths;
      setColumnWidths(newWidths);
      // @ts-ignore
      hideMenu?.();
    };

    return (
      <GridColumnMenu
        {...props}
        slots={{
          ...(columnWidths[colDef.field] && {
            columnMenuUserItem: () => (
              <MenuItem onClick={handleCustomAction}>
                <ListItemIcon>
                  <WidthNormalIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Reset width</ListItemText>
              </MenuItem>
            ),
          }),
        }}
        slotProps={{
          columnMenuUserItem: {
            // set `displayOrder` for the new item
            displayOrder: 15,
          },
        }}
      />
    );
  }

  const accessControlContext = useAccessControlContext();
  return (
    <>
      <Card>
        <SoftBox p={2} pb={1.5}>
          {props.filterComponent}
          <SoftBox
            style={{ height: props.gridBoxHeight, width: '100%', zIndex: 1 }}
          >
            <DataGrid
              apiRef={apiRef}
              columns={columnsWithWidths}
              columnVisibilityModel={columnModel}
              onColumnVisibilityModelChange={setColumnModel}
              onColumnWidthChange={handleColumnResize}
              loading={getEntitiesQuery.isFetching}
              rows={
                getEntitiesQuery.isSuccess
                  ? getEntitiesQuery.data.data || []
                  : []
              }
              rowCount={
                getEntitiesQuery.isSuccess
                  ? getEntitiesQuery.data.totalCount || 0
                  : 0
              }
              pagination
              paginationMode='server'
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[25, 50, 100]}
              sortingMode='server'
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              filterMode='server'
              filterModel={filterModel}
              onFilterModelChange={setFilterModel}
              checkboxSelection={props.checkboxSelection}
              keepNonExistentRowsSelected
              onRowSelectionModelChange={onRowSelectionModelChange}
              onRowDoubleClick={onRowDoubleClick}
              disableColumnFilter
              disableDensitySelector
              hideFooterSelectedRowCount={!props.checkboxSelection}
              initialState={{ density: 'compact' }}
              slots={{
                ...(!props.filterComponent && { toolbar: customGridToolbar }),
                columnMenu: GridColumnMenuWithResetWidth,
              }}
              style={{ borderStyle: 'none' }}
            />
          </SoftBox>

          {/* buttons section is displayed "inside grid" (using absolute positioning),
            but it must appear after the grid in dom so that buttons are being focused
            after the grid table according to tab ordering */}
          <SoftBox
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              zIndex: 2,
            }}
          >
            <SoftBox display='flex' justifyContent='end'>
              {accessControlContext.canImport && !!props.showImportButton && (
                <EntityImporter
                  importType={props.exportImportType!}
                  onProgressBackdropChange={
                    handleExportImportProgressBackdropChange
                  }
                  onNotificationChange={showNotification}
                  tabIndex={0}
                />
              )}
              {!!props.showExportButton && (
                <EntityExporter
                  exportType={props.exportImportType!}
                  exportFilter={exportFilter}
                  onProgressBackdropChange={
                    handleExportImportProgressBackdropChange
                  }
                  onNotificationChange={showNotification}
                  confirmationDialogText={
                    props.formatExportConfirmationDialogText
                      ? props.formatExportConfirmationDialogText(
                          selectedEntityIds,
                        )
                      : props.exportConfirmationDialogText
                  }
                  tabIndex={0}
                />
              )}
              {accessControlContext.canCreate() && !!props.showCopyButton && (
                <ResponsiveButton
                  label='Copy'
                  color='info'
                  icon='content_copy'
                  disabled={
                    selectedEntityIds.length !== 1 ||
                    !accessControlContext.canCreate(getSelectedEntity())
                  }
                  linkTo={props.addNewEntityPageUrl || ''}
                  linkState={{ copyFrom: getSelectedEntity() }}
                  tabIndex={0}
                />
              )}
              {accessControlContext.canCreate() && !!props.showAddButton && (
                <ResponsiveButton
                  label='Add'
                  color='info'
                  icon='add'
                  linkTo={props.addNewEntityPageUrl || ''}
                  tabIndex={0}
                />
              )}
              {!!props.showEditButton && (
                <ResponsiveButton
                  label={
                    !accessControlContext.canUpdate(getSelectedEntity())
                      ? 'View'
                      : props.editButtonLabel || 'Edit'
                  }
                  color='info'
                  icon={
                    !accessControlContext.canUpdate(getSelectedEntity())
                      ? 'visibility'
                      : props.editButtonIcon || 'edit'
                  }
                  disabled={selectedEntityIds.length !== 1}
                  onClick={() => {
                    if (props.formatEditEntityPageUrl) {
                      navigate(
                        props.formatEditEntityPageUrl(
                          selectedEntityIds[0],
                          setProgressBackdropIsOpen,
                          setNotificationErrorMessage,
                        ),
                      );
                    }
                  }}
                  tabIndex={0}
                />
              )}
              {accessControlContext.canDelete() && !!props.showRemoveButton && (
                <ResponsiveButton
                  label='Remove'
                  color='primary'
                  icon='delete'
                  disabled={
                    selectedEntityIds.length < 1 ||
                    (selectedEntityIds.length == 1 &&
                      !accessControlContext.canDelete(getSelectedEntity()))
                  }
                  onClick={onDeleteBtnClick}
                  tabIndex={0}
                />
              )}
            </SoftBox>
          </SoftBox>

          <ConfirmationDialog
            open={confirmDeleteDialogIsOpen}
            title='Delete'
            description={
              props.formatRemoveConfirmationDialogText
                ? props.formatRemoveConfirmationDialogText(selectedEntityIds)
                : 'Do you confirm delete?'
            }
            resultCallback={onConfirmDeleteDialogResult}
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={progressBackdropIsOpen}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </SoftBox>
      </Card>
      <Snackbar
        open={!!notificationMessage}
        autoHideDuration={progressBackdropIsOpen ? null : 6000}
        onClose={() => setNotificationMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity={notificationSeverity} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BaseListPage;
