/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';

const AccessControlContext = createContext<IAccessControlContext>(
  {} as IAccessControlContext,
);
export default AccessControlContext;

type IAccessControlContext = {
  canCreate: (entity?: any) => boolean;
  canUpdate: (entity?: any) => boolean;
  canDelete: (entity?: any) => boolean;
  canImport: boolean;
};

export function useAccessControlContext(): IAccessControlContext {
  return useContext(AccessControlContext);
}
