/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { createContext, useContext } from 'react';
import Profile from 'models/Profile';
import GroupedPermissions from '../../GroupedPermissions';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export default AuthContext;

export type IAuthContext = {
  isReady: boolean;
  profile: Profile | null;
  permissions: GroupedPermissions | null;
  isReCaptchaRequired: boolean;

  login: (username: string, password: string, reCaptchaToken?: string) => void;
  logout: () => void;
};

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}
