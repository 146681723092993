/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { memo } from 'react';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import isEqual from 'lodash/isEqual';
import InputWithLabel from 'components/input/InputWithLabel';
import SoftTypography from 'softUI/components/SoftTypography';

type PeriodMetrics = {
  gmBasic: number;
  gmH: number;
  gmWK: number;
  gmYR: number;
};

type PeriodMetricsCardProps = {
  metrics: PeriodMetrics;
};

const PeriodMetricsCard = ({ metrics }: PeriodMetricsCardProps) => {
  return (
    <Card sx={{ p: 2 }}>
      <SoftTypography variant='h6' sx={{ pl: 1 }}>
        Metrics
      </SoftTypography>
      <Grid2 container spacing={1}>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label='GM, %'
            readOnly={true}
            tabIndex={-1}
            value={metrics.gmBasic}
            type='number'
          />
        </Grid2>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label='GM, $/h'
            readOnly={true}
            tabIndex={-1}
            value={metrics.gmH}
            type='number'
          />
        </Grid2>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label='GM, $/wk'
            readOnly={true}
            tabIndex={-1}
            value={metrics.gmWK}
            type='number'
          />
        </Grid2>
        <Grid2 size={{ xs: 3, lg: 6 }}>
          <InputWithLabel
            label='GM, $/yr'
            readOnly={true}
            tabIndex={-1}
            value={metrics.gmYR}
            type='number'
          />
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default memo(PeriodMetricsCard, (before, after) =>
  isEqual(before.metrics, after.metrics),
) as typeof PeriodMetricsCard;
