/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { Dispatch, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert/Alert';
import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import Snackbar from '@mui/material/Snackbar';
import { isEqual } from 'lodash';
import { ValidationHelper } from 'util/ValidationHelper';
import downloadReport from 'util/downloadReport';
import ReportConfig from 'models/ReportConfig';
import BaseReport from 'models/reports/BaseReport';
import { getErrorMessage } from 'queries/ErrorResponse';
import reportConfigQueries from 'queries/ReportConfigQueries';
import reportQueries from 'queries/ReportQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import SelectWithLabel from 'components/input/SelectWithLabel';
import SoftBox from 'softUI/components/SoftBox';
import SoftButton from 'softUI/components/SoftButton';
import SoftProgress from 'softUI/components/SoftProgress';
import SoftTypography from 'softUI/components/SoftTypography';
import { setCurrentPageTitle, useSoftUIController } from 'softUI/context';

export async function validateParameters<T extends BaseReport>(
  parameters: T,
  validationHelper: ValidationHelper<T>,
  onValidationErrors?: Dispatch<any>,
): Promise<boolean> {
  const allFieldsErrors = await validationHelper.validate(parameters);
  if (Object.keys(allFieldsErrors).length > 0) {
    onValidationErrors?.(allFieldsErrors);
    return false;
  }
  onValidationErrors?.({});
  return true;
}

type BaseReportPageProps<T> = {
  title: string;
  reportType?: string;
  parameters: T;
  onValidateParameters?: () => Promise<boolean>;
  onLoadParameters?: (parameters: T) => void;
  disabled?: boolean;
  onInProcess?: Dispatch<boolean>;
  children: any;
};

const BaseReportPage = <T extends BaseReport>(
  props: BaseReportPageProps<T>,
) => {
  const [, dispatch]: any = useSoftUIController();
  useEffect(() => {
    setCurrentPageTitle(dispatch, props.title);
  }, [props.title]);
  const [inProcess, setInProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] =
    useState<AlertColor>('info');

  function showNotification(severity: AlertColor, message: string) {
    setNotificationSeverity(severity);
    setNotificationMessage(message);
  }
  function showErrorNotification(message: string) {
    showNotification('error', message);
  }

  useEffect(() => props.onInProcess?.(inProcess), [inProcess]);

  const handleGenerateBtnClick = async () => {
    if (props.onValidateParameters && !(await props.onValidateParameters())) {
      return;
    }

    setInProgress(true);
    setPercentage(0);
    setStatusMessage('Generating Report...');
    try {
      const reportDetails = await reportQueries.generate(props.parameters);
      pollAndThenDownloadGeneratedReport(reportDetails.id);
    } catch (error: any) {
      showErrorNotification(
        await getErrorMessage(error, 'Generate report request failed'),
      );
      setInProgress(false);
    }
  };

  const pollAndThenDownloadGeneratedReport = (id: number) => {
    const pollStartTime = Date.now();
    const generateReportMaxTimeout = 1800000;

    const timerId = setInterval(async () => {
      if (Date.now() - pollStartTime > generateReportMaxTimeout) {
        clearInterval(timerId);
        showErrorNotification(
          `Generate report timeout is reached, report is still not ready.`,
        );
        setInProgress(false);
        return;
      }

      try {
        const reportDetails = await reportQueries.getDetails(id);
        if (reportDetails.status === 'GENERATING') {
          setPercentage(reportDetails.progressPercent || 0);
        } else if (
          reportDetails.status === 'READY' ||
          reportDetails.status === 'CONSUMED'
        ) {
          clearInterval(timerId);
          downloadGeneratedReport(id, reportDetails.filename);
        } else if (reportDetails.status === 'ERROR') {
          clearInterval(timerId);
          showErrorNotification(
            `Generate report task ended with error status. Details: ${reportDetails.statusDetails}`,
          );
          setInProgress(false);
        }
      } catch (error: any) {
        clearInterval(timerId);
        showErrorNotification(
          await getErrorMessage(
            error,
            'Failed to obtain generated report status',
          ),
        );
        setInProgress(false);
      }
    }, 2000);
  };

  const downloadGeneratedReport = (id: number, fileName: string) => {
    setPercentage(100);
    setStatusMessage('Downloading generated report ...');

    downloadReport(id, fileName, showErrorNotification, () => {
      setInProgress(false);
    });
  };

  const handleResetConfig = () => {
    props.onLoadParameters?.({ type: props.reportType } as T);
  };

  const handleLoadConfig = (config: ReportConfig) => {
    props.onLoadParameters?.({
      ...config.parameters,
      type: props.reportType,
    } as T);
  };

  const deleteConfigQuery = reportConfigQueries.delete(props.reportType || '');
  const handleDeleteConfig = async (config: ReportConfig) => {
    try {
      if (config.id) {
        await deleteConfigQuery.mutateAsync(config.id);
        showNotification('info', 'Parameters successfully deleted');
      }
    } catch (error: any) {
      showErrorNotification(
        await getErrorMessage(error, 'Delete parameters request failed'),
      );
    }
  };

  const createConfigQuery = reportConfigQueries.create(props.reportType || '');
  const handleSaveConfig = async (name: string, parameters: BaseReport) => {
    try {
      const config = { name, parameters } as ReportConfig;
      const result = await createConfigQuery.mutateAsync(config);
      setSelectedConfig(result.data);
      showNotification('info', 'Parameters successfully saved');
    } catch (error: any) {
      showErrorNotification(
        await getErrorMessage(error, 'Save parameters request failed'),
      );
    }
  };

  const [selectedConfig, setSelectedConfig] = useState<ReportConfig | null>(
    null,
  );
  useEffect(() => {
    if (
      selectedConfig &&
      !isEqual(selectedConfig.parameters, props.parameters)
    ) {
      setSelectedConfig(null);
    }
  }, [props.parameters]);

  const [showSaveConfig, setShowSaveConfig] = useState(false);
  const [newConfigName, setNewConfigName] = useState('');

  return (
    <>
      <Card>
        <SoftBox p={2} pt={1}>
          <SoftBox
            sx={({ breakpoints }: any) => ({
              [breakpoints.up('md')]: {
                position: 'absolute',
                right: '20px',
                top: '20px',
              },
              [breakpoints.down('md')]: {
                paddingBottom: 3,
                display: 'flex',
                justifyContent: 'end',
              },
            })}
          >
            <SoftButton
              size='small'
              variant='contained'
              color='info'
              onClick={handleGenerateBtnClick}
              disabled={props.disabled || inProcess}
              tabIndex={2}
            >
              Generate
            </SoftButton>
          </SoftBox>
          {props.children}
          {!!props.reportType && (
            <SoftBox
              sx={{
                marginTop: 4,
                display: 'flex',
                justifyContent: 'start',
              }}
            >
              <SoftButton
                variant='outlined'
                color='info'
                size='small'
                onClick={handleResetConfig}
                disabled={props.disabled || inProcess}
                tabIndex={2}
              >
                Reset
              </SoftButton>
              <SoftButton
                variant='outlined'
                color='info'
                size='small'
                onClick={() => {
                  setShowSaveConfig((prevState) => !prevState);
                }}
                disabled={props.disabled || inProcess}
                tabIndex={2}
                sx={{ ml: 2 }}
              >
                Save As
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
      </Card>
      {!!props.reportType && !showSaveConfig && (
        <Card sx={{ mt: 2, p: 2 }}>
          <SoftTypography variant='h6' sx={{ pl: 1 }}>
            Saved Parameters
          </SoftTypography>
          <SoftBox sx={{ mt: 1.5 }}>
            <Grid2 container spacing={1} alignItems='flex-end'>
              <Grid2 size={{ xs: 6 }}>
                <SelectWithLabel
                  selectedValue={selectedConfig}
                  optionsFetcher={() =>
                    reportConfigQueries.getConfigs(props.reportType || '')
                  }
                  onChange={(selected) => {
                    setSelectedConfig(selected);
                    if (selected) handleLoadConfig(selected);
                  }}
                  disabled={props.disabled || inProcess}
                  tabIndex={3}
                />
              </Grid2>
              <Grid2 size={{ xs: 6 }} display='flex'>
                <SoftBox pl={2}>
                  <SoftButton
                    size='small'
                    variant='outlined'
                    component='label'
                    color='primary'
                    onClick={() => {
                      if (selectedConfig) handleDeleteConfig(selectedConfig);
                      setSelectedConfig(null);
                    }}
                    disabled={!selectedConfig || props.disabled || inProcess}
                    tabIndex={3}
                  >
                    Delete
                  </SoftButton>
                </SoftBox>
              </Grid2>
            </Grid2>
          </SoftBox>
        </Card>
      )}
      {!!props.reportType && showSaveConfig && (
        <Card sx={{ mt: 2, p: 2 }}>
          <SoftTypography variant='h6' sx={{ pl: 1 }}>
            Save As
          </SoftTypography>
          <SoftBox>
            <Grid2 container spacing={1} alignItems='flex-end'>
              <Grid2 size={{ xs: 6, md: 5, lg: 5 }}>
                <InputWithLabel
                  label='Name'
                  value={newConfigName}
                  onChange={(_, value) => setNewConfigName(value)}
                  disabled={props.disabled || inProcess}
                  tabIndex={3}
                />
              </Grid2>
              <Grid2 size={{ xs: 6, md: 4, lg: 3 }}>
                <SoftBox pl={2}>
                  <SoftButton
                    size='small'
                    variant='contained'
                    component='label'
                    color='info'
                    onClick={() => {
                      handleSaveConfig(newConfigName, props.parameters);
                      setNewConfigName('');
                      setShowSaveConfig(false);
                    }}
                    disabled={!newConfigName || props.disabled || inProcess}
                    tabIndex={3}
                  >
                    Save
                  </SoftButton>
                </SoftBox>
              </Grid2>
            </Grid2>
          </SoftBox>
        </Card>
      )}
      {inProcess && (
        <Card sx={{ mt: 1 }}>
          <SoftBox p={3}>
            <SoftTypography variant='h6' fontWeight='bold' color='text'>
              {statusMessage}
            </SoftTypography>
            <SoftBox mt={0.5}>
              <SoftProgress value={percentage} label />
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <Snackbar
        open={!!notificationMessage}
        autoHideDuration={6000}
        onClose={() => setNotificationMessage('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity={notificationSeverity} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BaseReportPage;
