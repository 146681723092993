/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import CircularProgress from '@mui/material/CircularProgress';
import CoverLayout from 'layouts/CoverLayout';
import curved9 from 'softUI/assets/images/curved-images/curved-6.jpg';

export default function AuthenticationPage() {
  return (
    <CoverLayout
      title='Catapult Accounting System'
      description='Please, wait...'
      image={curved9}
    >
      <CircularProgress color='inherit' />
    </CoverLayout>
  );
}
