/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { PermissionResource } from 'models/RolePermission';
import { useAuth } from '../Authentication/AuthContext';
import AccessControlContext from './AccessControlContext';
import ReadOnlyContextProvider from './ReadOnlyContextProvider';
import UnrestrictedAccessContextProvider from './UnrestrictedAccessContextProvider';
import { matchActionAndConditions } from './accessConditions';

const AccessControlContextProvider = ({
  resource,
  children,
}: {
  resource: PermissionResource;
  children: any;
}) => {
  const { profile, permissions } = useAuth();
  if (profile?.role?.isAdministrator) {
    return (
      <UnrestrictedAccessContextProvider>
        {children}
      </UnrestrictedAccessContextProvider>
    );
  }

  const resourcePermissions = permissions?.get(resource);
  if (
    !resourcePermissions?.length ||
    (resourcePermissions.length === 1 &&
      resourcePermissions[0].actions.length === 1 &&
      resourcePermissions[0].actions[0] === 'READ')
  ) {
    return <ReadOnlyContextProvider>{children}</ReadOnlyContextProvider>;
  }

  return (
    <AccessControlContext.Provider
      value={{
        canCreate: (entity?: any) =>
          matchActionAndConditions(resourcePermissions, 'CREATE', entity),
        canUpdate: (entity?: any) =>
          matchActionAndConditions(resourcePermissions, 'UPDATE', entity),
        canDelete: (entity?: any) =>
          matchActionAndConditions(resourcePermissions, 'DELETE', entity),
        canImport: matchActionAndConditions(
          resourcePermissions,
          'IMPORT',
          null,
        ),
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
};
export default AccessControlContextProvider;
