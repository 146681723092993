/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Profile from 'models/Profile';
import securityQueries from 'queries/SecurityQueries';
import GroupedPermissions, {
  getGroupedPermissions,
} from '../../GroupedPermissions';
import AuthContext, { IAuthContext } from './AuthContext';

export default function AuthContextProvider({ children }: any) {
  const [isReady, setReady] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [permissions, setPermissions] = useState<GroupedPermissions | null>(
    null,
  );
  const [isReCaptchaRequired, setReCaptchaRequired] = useState<boolean>(false);

  function setProfileAndPermissions(profile: Profile | null) {
    setProfile(profile);
    setPermissions(
      (profile?.role?.permissions &&
        getGroupedPermissions(profile.role.permissions)) ||
        null,
    );
  }

  useEffect(() => {
    (async () => {
      try {
        const rawResponse = await securityQueries.verifyLogin();
        setProfileAndPermissions(rawResponse.data.data);
      } catch (error: any) {
        console.log(error);
        cleanSession();
      }
      setReady(true);
    })();
  }, []);

  const logoutChannel = useMemo(() => new BroadcastChannel('tas_logout'), []);
  useEffect(() => {
    function messageEventListener() {
      cleanSession();
    }
    logoutChannel.addEventListener('message', messageEventListener);
    return () => {
      logoutChannel.removeEventListener('message', messageEventListener);
    };
  }, [logoutChannel]);

  const queryClient = useQueryClient();

  function cleanSession() {
    setProfileAndPermissions(null);
    queryClient.clear();
  }

  useEffect(() => {
    function unauthorizedEventListener(e: any) {
      const responseHeaders = e.detail?.response?.headers;
      setReCaptchaRequired(responseHeaders?.get('Captcha-Required') || false);
      cleanSession();
      logoutChannel.postMessage('unauthorized');
    }
    window.addEventListener('tas_http_unauthorized', unauthorizedEventListener);
    return () => {
      window.removeEventListener(
        'tas_http_unauthorized',
        unauthorizedEventListener,
      );
    };
  }, [queryClient]);

  const auth: IAuthContext = {
    isReady: isReady,
    profile: profile,
    permissions: permissions,
    isReCaptchaRequired: isReCaptchaRequired,

    async login(username: string, password: string, reCaptchaToken?: string) {
      try {
        // Clear all caches as data may require refetching because of access control.
        queryClient.clear();

        const rawResponse = await securityQueries.login(
          username,
          password,
          reCaptchaToken,
        );
        setProfileAndPermissions(rawResponse.data.data);
      } catch (error: any) {
        console.log(error);
        cleanSession();
        throw error;
      }
    },
    async logout() {
      await securityQueries.logout();
      cleanSession();
      logoutChannel.postMessage('logout');
    },
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
