/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AuthContextProvider from 'security/context/Authentication/AuthContextProvider';
import RootRoutes from 'routes/RootRoutes';
import http from 'queries/HttpClient';
import PeriodFilterProvider from 'components/CombinedFilter/context/PeriodFilterProvider';
import TimesheetFilterProvider from 'components/CombinedFilter/context/TimesheetFilterProvider';
import theme from 'softUI/assets/theme';

export default function App() {
  useEffect(() => {
    const handleUncaughtError = ({
      colno,
      error,
      filename,
      lineno,
      message,
    }: any) => {
      http
        .post(
          '/errors',
          {
            columnNumber: colno,
            filename,
            lineNumber: lineno,
            message,
            stack: error.stack,
            timestamp: new Date().toISOString(),
          },
          {
            validateStatus: (status) => status <= 401,
          },
        )
        .catch(() => {
          // ignore
        });
    };

    window.addEventListener('error', handleUncaughtError);
    return () => window.removeEventListener('error', handleUncaughtError);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <PeriodFilterProvider>
          <TimesheetFilterProvider>
            <RootRoutes />
          </TimesheetFilterProvider>
        </PeriodFilterProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}
