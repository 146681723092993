/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import { PermissionAction, PermissionResource } from 'models/RolePermission';

export type PermissionActionDef = {
  name: string;
  action: PermissionAction;
  activateActions?: PermissionAction[];
  deactivateActions?: PermissionAction[];
};

export type PermissionDef = {
  name: string;
  resources: Set<PermissionResource>;
  supportedActions: Set<PermissionAction>;
  supportConditions?: boolean;
};

export const appPermissionActions: PermissionActionDef[] = [
  {
    name: 'View',
    action: 'READ',
    deactivateActions: ['CREATE', 'UPDATE', 'DELETE', 'IMPORT'],
  },
  {
    name: 'Add',
    action: 'CREATE',
    activateActions: ['READ', 'UPDATE'],
  },
  {
    name: 'Edit',
    action: 'UPDATE',
    activateActions: ['READ'],
    deactivateActions: ['CREATE'],
  },
  {
    name: 'Delete',
    action: 'DELETE',
    activateActions: ['READ'],
  },
  {
    name: 'Import',
    action: 'IMPORT',
    activateActions: ['READ'],
  },
];
const allActions = new Set(appPermissionActions.map((def) => def.action));
const appPermissions: PermissionDef[] = [
  {
    name: 'Periods',
    resources: new Set(['PERIODS']),
    supportedActions: allActions,
    supportConditions: true,
  },
  {
    name: 'Timesheets',
    resources: new Set(['TIMESHEETS']),
    supportedActions: allActions,
    supportConditions: true,
  },
  {
    name: 'Dictionaries',
    resources: new Set([
      'CONSULTANTS',
      'CLIENTS',
      'POSITIONS',
      'INDUSTRYTYPES',
      'LOCATIONS',
      'EMPLOYMENTTYPES',
      'PAYPERIODS',
      'INVOICINGFREQUENCIES',
      'POOLS',
      'EMPLOYEES',
    ]),
    supportedActions: allActions,
  },
  {
    name: 'Reports',
    resources: new Set(['REPORTS']),
    supportedActions: new Set(['READ']),
  },
];
export default appPermissions;
