/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import { asUTC } from 'util/Formatters';
import Filter from 'models/Filter';
import TimesheetFilterContext, {
  ITimesheetFilterContext,
} from './TimesheetFilterContext';

export default function TimesheetFilterProvider({ children }: any) {
  const [predefinedFilterOption, setPredefinedFilterOption] = useState('');
  const [predefinedFilter, setPredefinedFilter] = useState({} as Filter);
  const [advancedFilter, setAdvancedFilter] = useState({} as Filter);

  const context = {
    filterSubject: 'timesheets',
    predefinedFilterOption: predefinedFilterOption,
    setPredefinedFilter(option: any, filter: Filter) {
      setPredefinedFilterOption(option);
      setPredefinedFilter(filter);
    },

    hasAdvancedFilter: () =>
      advancedFilter && JSON.stringify(advancedFilter) !== '{}',
    resetAdvancedFilter() {
      setAdvancedFilter({} as Filter);
    },
    buildFilter: () => ({ ...predefinedFilter, ...advancedFilter }),

    startDate: () =>
      advancedFilter.weekEndingDate?.start ||
      predefinedFilter.weekEndingDate?.start,
    isStartDateReadOnly: () => !!predefinedFilter.weekEndingDate,
    setStartDate(date: Date) {
      if (date || advancedFilter.weekEndingDate?.end) {
        setAdvancedFilter({
          ...advancedFilter,
          weekEndingDate: {
            start: date ? asUTC(date) : undefined,
            end: advancedFilter.weekEndingDate?.end,
          },
        });
      } else {
        setAdvancedFilter({
          ...advancedFilter,
          weekEndingDate: undefined,
        });
      }
    },
    endDate: () =>
      advancedFilter.weekEndingDate?.end ||
      predefinedFilter.weekEndingDate?.end,
    isEndDateReadOnly: () => !!predefinedFilter.weekEndingDate,
    setEndDate(date: Date) {
      if (date || advancedFilter.weekEndingDate?.start) {
        setAdvancedFilter({
          ...advancedFilter,
          weekEndingDate: {
            start: advancedFilter.weekEndingDate?.start,
            end: date ? asUTC(date) : undefined,
          },
        });
      } else {
        setAdvancedFilter({
          ...advancedFilter,
          weekEndingDate: undefined,
        });
      }
    },
    consultants: () =>
      'consultantIds' in advancedFilter
        ? advancedFilter.consultantIds
        : predefinedFilter.consultantIds,
    setConsultants(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, consultantIds: ids });
    },
    clients: () =>
      'clientIds' in advancedFilter
        ? advancedFilter.clientIds
        : predefinedFilter.clientIds,
    setClients(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, clientIds: ids });
    },
    recruiters: () =>
      'recruiterIds' in advancedFilter
        ? advancedFilter.recruiterIds
        : predefinedFilter.recruiterIds,
    setRecruiters(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, recruiterIds: ids });
    },
    accountExecutives: () =>
      'accountExecutiveIds' in advancedFilter
        ? advancedFilter.accountExecutiveIds
        : predefinedFilter.accountExecutiveIds,
    setAccountExecutives(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, accountExecutiveIds: ids });
    },
    positions: () =>
      'positionIds' in advancedFilter
        ? advancedFilter.positionIds
        : predefinedFilter.positionIds,
    setPositions(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, positionIds: ids });
    },
    employmentTypes: () =>
      'employmentTypeIds' in advancedFilter
        ? advancedFilter.employmentTypeIds
        : predefinedFilter.employmentTypeIds,
    setEmploymentTypes(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, employmentTypeIds: ids });
    },
    statuses: () =>
      'statusIds' in advancedFilter
        ? advancedFilter.statusIds
        : predefinedFilter.statusIds,
    setStatuses(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, statusIds: ids });
    },
    locations: () =>
      'locationIds' in advancedFilter
        ? advancedFilter.locationIds
        : predefinedFilter.locationIds,
    setLocations(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, locationIds: ids });
    },
    placementNumbers: () => advancedFilter.placementNumbers,
    setPlacementNumbers(items: string[]) {
      setAdvancedFilter({ ...advancedFilter, placementNumbers: items });
    },
    periods: () => advancedFilter.periodIds,
    setPeriods(ids: number[]) {
      setAdvancedFilter({ ...advancedFilter, periodIds: ids });
    },
  } as ITimesheetFilterContext;

  return (
    <TimesheetFilterContext.Provider value={context}>
      {children}
    </TimesheetFilterContext.Provider>
  );
}
