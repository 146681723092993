/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import {
  UseQueryResult,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ReportTemplate from 'models/ReportTemplate';
import ReportType from 'models/ReportType';
import http from './HttpClient';

class ReportTemplateQueries {
  getTypes(): UseQueryResult<ReportType[]> {
    const url = `reporttemplates`;
    return useQuery({
      queryKey: [url],
      queryFn: async () => {
        const response = await http.get<ReportType[]>(url);
        return response.data;
      },

      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      placeholderData: keepPreviousData,
    });
  }

  getTemplates(type: ReportType): UseQueryResult<ReportTemplate[]> {
    const url = `reporttemplates/${type}`;
    return useQuery({
      queryKey: [url],
      queryFn: async () => {
        if (type) {
          const response = await http.get<ReportTemplate[]>(url);
          return response.data;
        }
        return [];
      },

      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      placeholderData: keepPreviousData,
    });
  }

  async create(
    type: ReportType,
    name: string,
    file: File,
  ): Promise<ReportTemplate> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    const response = await http.postForm(`/reporttemplates/${type}`, formData);
    return response.data;
  }

  async getContent(
    type: ReportType,
    templateId: number | undefined,
  ): Promise<AxiosResponse<Blob>> {
    return await http.get(
      `/reporttemplates/${type}/${templateId || 'default'}/content`,
      {
        responseType: 'blob',
      },
    );
  }

  async delete(type: ReportType, templateId: number) {
    await http.delete(`/reporttemplates/${type}/${templateId}`);
  }
}

export default new ReportTemplateQueries();
