/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AccessControlContextProvider from 'security/context/AccessControl/AccessControlContextProvider';
import * as Yup from 'yup';
import { ValidationHelper } from 'util/ValidationHelper';
import Location from 'models/Location';
import locationQueries from 'queries/LocationQueries';
import InputWithLabel from 'components/input/InputWithLabel';
import SwitchWithLabel from 'components/input/SwitchWithLabel';
import BasePage, { BasicPageProps } from 'pages/BasePage';

const validationHelper = new ValidationHelper<Location>(
  Yup.object({
    name: Yup.string()
      .required('Must be present')
      .max(200, 'Must not exceed 200 chars'),
    abbreviation: Yup.string()
      .required('Must be present')
      .max(3, 'Must not exceed 30 chars'),
    isActive: Yup.boolean().required('Must be present'),
  }),
);

const LocationPage = (props: BasicPageProps) => {
  function createEmptyLocation(): Location {
    return { id: -1, name: '', abbreviation: '', isActive: true };
  }

  const ParameterizedBasePage = BasePage<Location>;
  const locationPage = (
    <ParameterizedBasePage
      {...props}
      emptyEditedEntityFactory={createEmptyLocation}
      queriesImpl={locationQueries}
      validationHelper={validationHelper}
      confirmCloseDialogText='Are you sure you want to reject changes made to current Location?'
      confirmRemovalDialogText='Are you sure you want to delete current Location?'
      confirmSaveDialogText='Are you sure you want to save changes to current Location?'
      confirmResetDialogText='Are you sure you want to reset changes performed to current Location?'
      formatEditEntityPageUrl={(id) => `/locations/edit/${id}`}
    >
      {(sectionParams) => (
        <Card sx={{ p: 2, pt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Name'
                size='small'
                autoFocus={true}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.name}
                onChange={sectionParams.updateInputField('name')}
                errorText={sectionParams.validationErrors.name?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputWithLabel
                label='Abbreviation'
                size='small'
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.abbreviation}
                onChange={sectionParams.updateInputField('abbreviation')}
                errorText={sectionParams.validationErrors.abbreviation?.join(
                  ';',
                )}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SwitchWithLabel
                label='Active'
                tabIndex={sectionParams.tabIndexForInputs}
                checked={sectionParams.editedData.isActive}
                onChange={sectionParams.updateInputField('isActive')}
                error={sectionParams.validationErrors.isActive?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputWithLabel
                label='Notes'
                size='small'
                multiline={true}
                multilineRows={3}
                tabIndex={sectionParams.tabIndexForInputs}
                value={sectionParams.editedData.notes}
                onChange={sectionParams.updateInputField('notes')}
                errorText={sectionParams.validationErrors.notes?.join(';')}
                readOnly={sectionParams.readOnly}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </ParameterizedBasePage>
  );

  return (
    <AccessControlContextProvider resource='LOCATIONS'>
      {locationPage}
    </AccessControlContextProvider>
  );
};

export default LocationPage;
