/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import AccessControlContext from './AccessControlContext';

const UnrestrictedAccessContextProvider = ({ children }: any) => {
  return (
    <AccessControlContext.Provider
      value={{
        canCreate: () => true,
        canUpdate: () => true,
        canDelete: () => true,
        canImport: true,
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
};
export default UnrestrictedAccessContextProvider;
