/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import SystemConfig from 'models/SystemConfig';
import http from './HttpClient';

class SystemConfigQueries {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  get(): UseQueryResult<SystemConfig[]> {
    const url = this.url;
    return useQuery({
      queryKey: [url],
      queryFn: async () => {
        const response = await http.get<SystemConfig[]>(url);
        return response.data;
      },
    });
  }

  update(): UseMutationResult<any, any, SystemConfig[], any> {
    const url = this.url;
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (model: SystemConfig[]) => {
        return http.put(url, model);
      },
      onSuccess: () => queryClient.removeQueries({ queryKey: [url] }),
    });
  }
}

export default new SystemConfigQueries('/configs');
