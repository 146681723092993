/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';
import { ValidationErrors, ValidationHelper } from 'util/ValidationHelper';
import { getClientNameWithUid } from 'models/Client';
import MissingTimesheetsReport from 'models/reports/MissingTimesheetsReport';
import clientQueries from 'queries/ClientQueries';
import employmentTypeQueries from 'queries/EmploymentTypeQueries';
import locationQueries from 'queries/LocationQueries';
import statusQueries from 'queries/StatusQueries';
import AutocompleteWithLabel from 'components/input/AutocompleteWithLabel';
import BaseReportPage, { validateParameters } from './BaseReportPage';
import DatesForReportParameters from './filters/DatesForReportParameters';

const validationHelper = new ValidationHelper<MissingTimesheetsReport>(
  Yup.object({
    startDate: Yup.date().required('Must be present'),
    endDate: Yup.date().required('Must be present'),
  }),
);

const MissingTimesheetsReportPage = () => {
  const [parameters, setParameters] = useState({
    type: 'MissingTimesheet',
  } as MissingTimesheetsReport);
  const [validationErrors, setValidationErrors] = useState(
    {} as ValidationErrors<MissingTimesheetsReport>,
  );
  const [disabled, setDisabled] = useState(false);

  return (
    <BaseReportPage
      title='Missing Timesheets Report'
      reportType={'MissingTimesheet'}
      parameters={parameters}
      onValidateParameters={() =>
        validateParameters(parameters, validationHelper, setValidationErrors)
      }
      onLoadParameters={setParameters}
      onInProcess={setDisabled}
    >
      <Grid container>
        <DatesForReportParameters
          parameters={parameters}
          onUpdateParameters={setParameters}
          validationErrors={validationErrors}
          disabled={disabled}
          autoFocus={true}
          tabIndexForInputs={1}
        />
        <Grid container item spacing={2} xs={12} pt={3}>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Clients'
              tabIndex={1}
              optionsFetcher={clientQueries}
              optionLabelGetter={getClientNameWithUid}
              selectedValues={
                parameters.clientIds?.map((id) => ({ id: id }) as any) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  clientIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Locations'
              tabIndex={1}
              optionsFetcher={locationQueries}
              optionFilter={(l) => l.isActive}
              selectedValues={
                parameters.locationIds?.map((id) => ({ id: id }) as any) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  locationIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Employment Types'
              tabIndex={1}
              optionsFetcher={employmentTypeQueries}
              optionFilter={(t) => t.isActive}
              selectedValues={
                parameters.employmentTypeIds?.map(
                  (id) => ({ id: id }) as any,
                ) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  employmentTypeIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} md={4} xl={3}>
            <AutocompleteWithLabel
              label='Statuses'
              tabIndex={1}
              optionsFetcher={statusQueries}
              selectedValues={
                parameters.statusIds?.map((id) => ({ id: id }) as any) || []
              }
              onChange={(_, ids) => {
                setParameters({
                  ...parameters,
                  statusIds: ids || [],
                });
              }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseReportPage>
  );
};

export default MissingTimesheetsReportPage;
