/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import AccessControlContextProvider from 'security/context/AccessControl/AccessControlContextProvider';
import { ExportImportType } from 'queries/ExportImportQueries';
import positionQueries from 'queries/PositionQueries';
import BaseListPage, {
  BasicPageProperties,
  PageProperties,
} from 'pages/BaseListPage';

const PositionsPage = ({ title }: BasicPageProperties) => {
  const properties: PageProperties = {
    title: title,
    localStorageItemName: 'positionDataTableState',
    addNewEntityPageUrl: '/positions/new',
    formatEditEntityPageUrl(id) {
      return `/positions/edit/${id}`;
    },
    formatRemoveConfirmationDialogText(id) {
      if (Array.isArray(id) && id.length > 1) {
        return `Are you sure you want to remove ${id.length} selected Positions?`;
      }
      return `Are you sure you want to remove selected Position with ID ${id}?`;
    },
    formatExportConfirmationDialogText(ids) {
      return `Are you sure you want to export ${
        ids.length > 0 ? ids.length : 'all found'
      } Positions?`;
    },
    checkboxSelection: true,
    gridBoxHeight: 'calc(100vh - 117px)',
    showAddButton: true,
    showEditButton: true,
    showRemoveButton: true,
    showExportButton: true,
    showImportButton: true,
    exportImportType: ExportImportType.Positions,
    queriesImpl: positionQueries,
    gridColumns: [
      { field: 'id', headerName: '#', width: 80 },
      { field: 'name', headerName: 'Name', flex: 2 },
      { field: 'notes', headerName: 'Notes', flex: 1 },
    ],
    defaultSort: { field: 'name', sort: 'asc' },
  };

  return (
    <AccessControlContextProvider resource='POSITIONS'>
      <BaseListPage {...properties} />
    </AccessControlContextProvider>
  );
};

export default PositionsPage;
