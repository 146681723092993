/*
 * Copyright © 2023 TEAM International Services Inc. All Rights Reserved.
 */
import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select';
import pxToRem from 'softUI/assets/theme/functions/pxToRem';
import IFilterContext from '../context/IFilterContext';
import { PredefinedFilterOptions } from './options/PredefinedFilterOptions';

type PredefinedFilterProps = {
  predefinedFilterOptions: PredefinedFilterOptions;
  onChange?: any;
  passResetFunction?: any;
  filterContext: () => IFilterContext;
};

const PredefinedFilter = ({
  predefinedFilterOptions,
  onChange,
  passResetFunction,
  filterContext: filterContextSupplier,
}: PredefinedFilterProps) => {
  const filterContext = filterContextSupplier();
  useEffect(() => {
    if (!filterContext.predefinedFilterOption) {
      filterContext.setPredefinedFilter(
        predefinedFilterOptions.defaultOption,
        {},
      );
    }
  });

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    filterContext.setPredefinedFilter(
      event.target.value,
      predefinedFilterOptions.buildPredefinedFilter(event.target.value),
    );
    onChange?.();
  };

  passResetFunction?.(() => {
    filterContext.setPredefinedFilter(
      predefinedFilterOptions.defaultOption,
      {},
    );
  });

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel
        id='predefined-filters-select-label'
        sx={{ fontSize: '1rem' }}
      >
        Predefined filters
      </InputLabel>
      <Select
        id='predefined-filters-select'
        labelId='predefined-filters-select-label'
        label='Predefined filters'
        value={filterContext.predefinedFilterOption}
        autoFocus={true}
        onChange={onChangeHandler}
        sx={{
          padding: '0px !important',

          '.MuiSelect-select': {
            width: '100% !important',
            boxSizing: 'border-box',
            padding: `${pxToRem(6)} 0px 
                      ${pxToRem(5)} ${pxToRem(12)} !important`,
          },
        }}
      >
        {Object.values(predefinedFilterOptions.enumeration).map(
          (option: any) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
};

export default PredefinedFilter;
