/*
 * Copyright © 2024 TEAM International Services Inc. All Rights Reserved.
 */
import RolePermission from 'models/RolePermission';

type PermissionConditions = {
  startDate?: string;
  endDate?: string;
  maxAgeDays?: number;
};
export default PermissionConditions;

export function extractConditions(
  permission: RolePermission,
): PermissionConditions | undefined {
  const startDate = permission.startDate ?? null;
  const endDate = permission.endDate ?? null;
  const maxAgeDays = permission.maxAgeDays ?? null;
  if (startDate || endDate || maxAgeDays !== null) {
    const result = {} as PermissionConditions;
    if (startDate) {
      result.startDate = startDate;
    }
    if (endDate) {
      result.endDate = endDate;
    }
    if (maxAgeDays !== null) {
      result.maxAgeDays = maxAgeDays;
    }
    return result;
  }
}

export function applyConditions(
  permission: RolePermission,
  conditions: PermissionConditions | undefined,
): RolePermission {
  if (conditions?.startDate) {
    permission.startDate = conditions.startDate;
  } else {
    delete permission.startDate;
  }
  if (conditions?.endDate) {
    permission.endDate = conditions.endDate;
  } else {
    delete permission.endDate;
  }
  if (conditions?.maxAgeDays !== undefined) {
    permission.maxAgeDays = conditions.maxAgeDays;
  } else {
    delete permission.maxAgeDays;
  }
  return permission;
}
